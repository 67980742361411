import { memo } from 'react';

import cn from 'clsx';

import { CuiOptionType, Dropdown } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import { useFilterHeaderTranslation } from './hooks';

interface FiltersProps {
  className?: string;
  title?: string;
  options: CuiOptionType[];
  selected: CuiOptionType;
  onChange: (selected: CuiOptionType) => void;
  'data-testid'?: string;
}

export const FilterHeader: React.FC<FiltersProps> = ({
  className,
  title,
  options,
  selected,
  onChange,
  'data-testid': dataTestId,
}) => {
  const { t } = useFilterHeaderTranslation();

  const { isDark } = useUITheme();

  return (
    <div
      className={cn(
        'tw-flex tw-flex-col tw-mb-1.6 sm:tw-mb-2.4',
        title &&
          'md:tw-flex-row-reverse md:tw-items-center md:tw-justify-between',
        className,
      )}
    >
      <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-end">
        <span className="tw-font-label tw-mb-0.8 sm:tw-mb-0 sm:tw-mr-2.4">
          {t('UD_FILTER_LABEL')}
        </span>
        <Dropdown
          onSelected={selectedOption => {
            if (selectedOption) {
              onChange(selectedOption);
            }
          }}
          options={options}
          selected={selected}
          isDark={isDark}
          size={options.length}
          data-testid={dataTestId}
        />
      </div>
      {title && (
        <span className="tw-font-subtitle3 tw-mt-2.4 md:tw-mt-0">{title}</span>
      )}
    </div>
  );
};

export default memo(FilterHeader);

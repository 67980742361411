import { FC } from 'react';

import { Container } from '@crehana/ui';
import { Loadable } from '@crehana/web';

import DashboardErrorView from '@/shared/components/DashboardErrorView';

import DiplomaListSkeleton from './components/DiplomaListSkeleton';
import { DiplomasProps } from './types';

const LazyDiplomas: FC<React.PropsWithChildren<DiplomasProps>> = props => (
  <Loadable
    Loader={
      <Container type="dashboard">
        <DiplomaListSkeleton />
      </Container>
    }
    ErrorFallback={
      <Container type="dashboard">
        <DashboardErrorView defaultConnectionError supportsDarkMode />
      </Container>
    }
    module={() =>
      import(/* webpackChunkName: "user-dashboard-diplomas" */ './Diplomas')
    }
    {...props}
  />
);

export default LazyDiplomas;

import { MembershipTypeTag } from '@crehana/ts-types';

export const getMembershipDisplayName = (tag: MembershipTypeTag) => {
  switch (tag) {
    case MembershipTypeTag.PREMIUM:
      return 'Individual';
    case MembershipTypeTag.PLUS:
      return 'Membresia Plus';
    case MembershipTypeTag.PROFESIONAL:
      return 'Membresia Pro';
    case MembershipTypeTag.FAMILIAR:
      return 'Familiar';
    case MembershipTypeTag.PREMIUM_DUO:
      return 'Dúo';
    default:
      return '';
  }
};

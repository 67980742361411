import { FC } from 'react';

import { clsx } from 'clsx';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import Pencil from '@crehana/icons/Pencil';
import PencilRuler from '@crehana/icons/PencilRuler';
import { Clamp, LazyImage, transition } from '@crehana/ui';
import { date } from '@crehana/utils';
import { useMe, useUITheme } from '@crehana/web';
import ProjectEmptyCover from '@crehana/web/components/ProjectEmptyCover';
import ProjectStateTag from '@crehana/web/components/Tags/ProjectStateTag';
import { MyProject, StatusProjectEnum } from '@crehana/web/Graphql/types';

import { useProjectsTranslation } from '../hooks';

interface ProjectCardProps extends MyProject {
  amplitudePageName: string;
}

const ProjectCard: FC<React.PropsWithChildren<ProjectCardProps>> = ({
  title,
  publishDate,
  updatedAt,
  projectImageUrl,
  absoluteUrl,
  course,
  projectUserEnrollmentType,
  currentActionState,
  isFromDegree,
  isExpired,
  amplitudePageName,
}) => {
  const { t, languageCode } = useProjectsTranslation();
  const { isDark } = useUITheme();
  const { me } = useMe();

  const isCertified =
    currentActionState === StatusProjectEnum.CERTIFIED ||
    currentActionState === StatusProjectEnum.FINISHED;
  const Icon = isCertified ? PencilRuler : Pencil;
  const overlayLabel = isCertified
    ? t('UDP_GO_TO_PROJECT')
    : t('UDP_EDIT_PROJECT');
  const defaultTextColorClassName = isDark ? 'text-white' : 'text-base-main';
  const certificationDate = isCertified
    ? date.getGreaterDate(publishDate, updatedAt)
    : undefined;
  const projectUrl = createUrlWithSourceQueryParams({
    url: absoluteUrl || '',
    source: amplitudePageName,
    sourceDetail: 'Project Card',
  });

  return (
    <div
      className={`w-full h-full flex flex-col ${
        isDark ? 'bg-base-lighter-16' : 'bg-white shadow-tooltip'
      }`}
    >
      <a
        className="w-full h-280 sm:h-212 relative flex flex-shrink-0 z-1"
        href={projectUrl}
      >
        {projectImageUrl ? (
          <LazyImage
            className="w-full h-full"
            imgClassName="object-cover"
            src={projectImageUrl}
            imgxParams={{ h: 280, dpr: 2, crop: 'faces' }}
            alt={title || ''}
            dark={isDark}
          />
        ) : (
          <ProjectEmptyCover className="w-full h-full" isDark={isDark} />
        )}
        <div
          className="absolute inset-0 hidden sm:flex items-center justify-center bg-base-main-80 z-1 opacity-0 hover:opacity-100 font-link text-white"
          style={{ transition: transition('opacity') }}
        >
          <Icon size={24} ignoreColor className="fill-current mr-8" />
          {overlayLabel}
        </div>
      </a>
      <div className="w-full h-full flex flex-col justify-between p-16">
        <div className="flex flex-col items-start mb-24">
          <ProjectStateTag
            className="mb-16"
            projectType={isFromDegree ? 'degree' : undefined}
            projectTypeParams={{
              user: {
                originalId: me?.originalId || null,
                userTagV2: me?.userTagV2 || null,
              },
              course: {
                courseTypeEnum: course?.courseTypeEnum || null,
                teacherId: course?.professor?.originalId || null,
              },
              enrollment: {
                enrollmentTypeEnum: projectUserEnrollmentType || null,
                projectFeedbackIsAllowed:
                  !!course?.permissionsV2?.canFeedbackProject,
              },
            }}
            currentActionState={currentActionState || null}
            isExpired={!!isExpired}
          />
          <a
            href={projectUrl}
            className={clsx(
              'font-subtitle3',
              title ? defaultTextColorClassName : 'text-gray-dark',
            )}
          >
            {title ? <Clamp clamp={2}>{title}</Clamp> : t('UDP_EMPTY_TITLE')}
          </a>
          {course?.title && (
            <a
              href={course.dashboardUrl || ''}
              className={clsx('font-body4 mt-8', defaultTextColorClassName)}
            >
              <Clamp clamp={2}>{course.title}</Clamp>
            </a>
          )}
        </div>
        {updatedAt && (
          <span className="font-body4 text-base-lighter block mt-8">
            {certificationDate
              ? t('UDP_PUBLISHED_AT', {
                  interpolation: {
                    date: date.format(
                      certificationDate,
                      'DD/MM/YYYY',
                      undefined,
                      languageCode,
                    )!,
                  },
                })
              : t('UDP_UPDATED_AT', {
                  interpolation: {
                    date: date.humanize(updatedAt, languageCode).toLowerCase(),
                  },
                })}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;

import type { TPageTranslations } from '@crehana/i18n';

import { DASHBOARD_ERROR_VIEW_TRANSLATION_KEY } from '../constants';
import type { DashboardErrorViewLocaleKeys } from '../types';

const localePt: TPageTranslations<
  DashboardErrorViewLocaleKeys,
  typeof DASHBOARD_ERROR_VIEW_TRANSLATION_KEY
> = {
  [DASHBOARD_ERROR_VIEW_TRANSLATION_KEY]: {
    GENERAL_ERROR: 'Ocorreu um erro',
    TRY_AGAIN: 'Tente novamente',
    INTERNET_CONNECTION_ERROR: 'Ocorreu um erro de conexão',
    RELOAD_PAGE: 'Recarregue a página',
  },
};

export default localePt;

import { useQuery } from '@apollo/client';

import MEMBERSHIP_PRICES_QUERY from '../graphql/DialogGetPhoneMembershipPrices.graphql';
import { DialogGetPhoneMembershipPricesShared } from '../graphql/types/DialogGetPhoneMembershipPricesShared';
import { Prices } from '../types';

function useMembershipPrices(): Prices | undefined {
  const { data } = useQuery<DialogGetPhoneMembershipPricesShared>(
    MEMBERSHIP_PRICES_QUERY,
  );

  let prices: Prices | undefined;

  if (data?.membershipTypesV2?.edges.length) {
    const priceRes = data.membershipTypesV2.edges[0]?.node?.prices;

    prices = {
      total: String(priceRes?.total) || '',
      currencySymbol: priceRes?.currencySymbol || '',
    };
  }

  return prices;
}

export default useMembershipPrices;

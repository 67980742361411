import type { TPageTranslations } from '@crehana/i18n';

import { PROJECTS_TRANSLATION_KEY } from '../constants';
import type { ProjectsLocaleKeys } from '../types';

const localePt: TPageTranslations<
  ProjectsLocaleKeys,
  typeof PROJECTS_TRANSLATION_KEY
> = {
  [PROJECTS_TRANSLATION_KEY]: {
    // Filters
    UDP_FILTER_ALL_TITLE: 'Você tem {{count}} projeto no total',
    UDP_FILTER_ALL_TITLE_plural: 'Você tem {{count}} projetos no total',
    UDP_FILTER_CERTIFIED_TITLE: 'Você tem {{count}} projeto certificado',
    UDP_FILTER_CERTIFIED_TITLE_plural:
      'Você tem {{count}} projetos certificados',
    UDP_FILTER_IN_PROGRESS_TITLE: 'Você tem {{count}} projeto em progresso',
    UDP_FILTER_IN_PROGRESS_TITLE_plural:
      'Você tem {{count}} projetos em progresso',
    UDP_FILTER_ALL_LABEL: 'Todos',
    UDP_FILTER_CERTIFIED_LABEL: 'Certificados',
    UDP_FILTER_IN_PROGRESS_LABEL: 'Em progresso',
    // Empty view
    UDP_ALL_EMPTY_TITLE: 'Parece que você ainda não iniciou nenhum projeto',
    UDP_ALL_EMPTY_DESC:
      'Lembre-se de que, para obter seu diploma na Crehana, você deve não apenas terminar suas aulas mas também enviar e ter aprovação no seu projeto final. Para começar, você deve ir para a seção "Seu projeto final" no seu curso, onde pode encontrar todas as especificações do projeto a ser entregue.',
    UDP_ALL_EMPTY_BUTTON: 'Ir a meus cursos',
    UDP_ALL_EMPTY_BUTTON_USER_HE: 'Ir a meus rutas',
    UDP_CERTIFIED_EMPTY_TITLE: 'Você ainda não possui projetos certificados',
    UDP_CERTIFIED_EMPTY_DESC:
      'Completa todos os resultados finais de seus projetos e solicite feedback de nossos mentores para obter seu diploma.',
    UDP_CERTIFIED_EMPTY_BUTTON: 'Ver projetos em progresso',
    UDP_IN_PROGRESS_EMPTY_TITLE:
      'No momento, você não tem projetos em andamento',
    UDP_IN_PROGRESS_EMPTY_DESC:
      'Lembre-se de que, para obter seu diploma em Crehana, você deve não apenas terminar suas aulas, mas também aprovar o seu projeto final. Para começar, você deve acessar a seção "Seu projeto final" no curso, onde pode encontrar todas as especificações do projeto.',
    UDP_IN_PROGRESS_EMPTY_BUTTON: 'Ir a meus cursos',
    UDP_DEGREE_EMPTY_DESC:
      'Recuerda que a medida que avances con tus cursos, deberás realizar obligatoriamente los proyectos parciales y el proyecto final de tu MicroDegree.',
    UDP_DEGREE_EMPTY_BUTTON: 'Ir a mi MicroDegree',
    // Error view
    UDP_PROJECTS_ERROR: 'Ocorreu um erro ao obter seus projetos',
    // Project card
    UDP_EMPTY_TITLE: 'Projeto sem título',
    UDP_FREE_COURSE: 'Curso livre',
    UDP_UPDATED_AT: 'Última atualização {{date}}',
    UDP_PUBLISHED_AT: 'Publicado em {{date}}',
    UDP_EDIT_PROJECT: 'Edite seu projeto',
    UDP_GO_TO_PROJECT: 'Ir ao projeto',
    // Filter header
    UD_FILTER_LABEL: 'Filtrar por',
  },
};

export default localePt;

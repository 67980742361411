import { PROJECTS_TRANSLATION_KEY } from './constants';
import localeEs from './i18n/localeEs';

const locales = localeEs[PROJECTS_TRANSLATION_KEY];

export type ProjectsLocaleKeys = keyof typeof locales;

export type ProjectsProps = {
  amplitudePageName: string;
  enrollmentsCoursesRoutePath: string;
  enrollmentsDegreesRoutePath: string;
  enrollmentsRoute: string;
};

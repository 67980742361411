import * as React from 'react';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import CrehanaLogo from '@crehana/icons/logos/Crehana';
import CrehanaIso from '@crehana/icons/logos/CrehanaIso';
import { colors, InverseButton, LazyImage } from '@crehana/ui';

import { RENEWAL_CARD_CTA_AMPLITUDE } from './constants';
import { useRenewalCardTranslation } from './hooks';
import type { RenewalCardProps } from './types';
import { getMembershipDisplayName } from './utils';

const { white } = colors;

const RenewalCard: React.FC<React.PropsWithChildren<RenewalCardProps>> = ({
  firstname,
  remainingDays,
  membershipTypeTag,
}) => {
  const { t } = useRenewalCardTranslation();

  const redirectToRenewal = () => {
    window.location.href = createUrlWithSourceQueryParams({
      url: '/renovacion/',
      source: RENEWAL_CARD_CTA_AMPLITUDE.SOURCE,
      sourceDetail: RENEWAL_CARD_CTA_AMPLITUDE.SOURCE_DETAIL,
    });
  };

  return (
    <div className="relative min-h-360 flex flex-col rounded-5 overflow-hidden bg-base-lighter-24 shadow-card w-full z-0 md:my-4">
      <div className="p-16 text-center text-white mt-auto z-5">
        <LazyImage
          src="https://s3.amazonaws.com/public-catalog.crehana.com/images/app/gallery/2021/11/02/d6f789e3/8225d9b5.jpg"
          className="absolute left-0 top-0 w-full h-full -z-1 pointer-events-none"
          imgClassName="object-cover"
          alt="Background"
          isTransparent
        />

        <div className="mb-4">
          <CrehanaLogo
            size={16}
            color={white}
            secondaryColor={white}
            aria-hidden
          />
          {'  '}
          <CrehanaIso
            size={12}
            color={white}
            secondaryColor={white}
            aria-hidden
          />
        </div>

        <div
          className="tw-font-pop text-white bg-black max-w-200 pl-8 pr-4 py-4 text-16 border-solid border-2 border-white whitespace-nowrap font-semibold mb-24 mx-auto"
          style={{
            letterSpacing: '0.3em',
            width: 'max-content',
          }}
        >
          BLACK DAYS
        </div>

        <h3 className="mb-16 px-8 font-subtitle2">
          {t(
            firstname
              ? 'CARD_RENEWAL_TITLE_WITH_NAME'
              : 'CARD_RENEWAL_TITLE_WITHOUT_NAME',
            {
              interpolation: { firstname },
            },
          )}
        </h3>
        <p className="mb-16 px-2 font-body4">
          {t('CARD_RENEWAL_MESSAGE', {
            interpolation: {
              remainingDays,
              membershipType: getMembershipDisplayName(membershipTypeTag),
            },
          })}
        </p>
        <InverseButton
          full
          isDark
          isWhite
          size="m"
          label={t('CARD_RENEWAL_CTA')}
          onClick={redirectToRenewal}
        />
      </div>
    </div>
  );
};

export default RenewalCard;

import { forwardRef } from 'react';

import { cn } from '@crehana/compass.ui/v2';

const ButtonFilter = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const { children, className, ...propsRest } = props;

  return (
    <button
      type="button"
      className={cn(
        // Size and spacing
        'tw-flex tw-items-center tw-gap-0.4',
        'tw-p-0.8 tw-w-full',
        // Text
        '!tw-text-1.2 tw-font-subtitle5',
        'tw-text-neutral-light-800 dark:tw-text-neutral-dark-100',
        // Border
        'tw-border tw-border-solid tw-rounded-0.8',
        'tw-border-neutral-light-500 dark:tw-border-neutral-dark-600',
        className,
      )}
      ref={ref}
      {...propsRest}
    >
      {children}
    </button>
  );
});
ButtonFilter.displayName = 'ButtonFilter';

export default ButtonFilter;

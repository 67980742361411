import { RENEWAL_CARD_TRANSLATION_KEY } from '../constants';

const localeEn = {
  [RENEWAL_CARD_TRANSLATION_KEY]: {
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, congratulations on your achievements this year!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME:
      'Congratulations on your achievements this year!',
    CARD_RENEWAL_MESSAGE:
      'You have {{remainingDays}} more days to enjoy your {{membershipType}} membership. Shall we go for another year? Renew and keep growing.',
    CARD_RENEWAL_CTA: 'Renew Now',
  },
};

export default localeEn;

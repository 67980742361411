import type { I18nTranslation } from '@crehana/i18n';
import type { TSetTranslationKeys } from '@crehana/ts-types';

import localeEn from './localeEn';
import localeEs from './localeEs';
import localePt from './localePt';

export type UserStudyPlanTranslationKeys = TSetTranslationKeys<typeof localeEs>;

const locales: I18nTranslation = { es: localeEs, pt: localePt, en: localeEn };

export { localeEn, localeEs, localePt };
export default locales;

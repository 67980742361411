import { USER_STUDY_PLAN_TRANSLATION_KEY } from '../constants';

const es = {
  [USER_STUDY_PLAN_TRANSLATION_KEY]: {
    USP_STUDY_PLAN_TITLE: 'Programa tus horas de estudio',
    USP_STUDY_PLAN_DESCRIPTION:
      'Establecer una meta diaria de aprendizaje te ayudará a mantenerte motivado. Cuéntanos cuánto tiempo dedicarás a estudiar en Crehana y nosotros te ayudaremos a cumplir tus metas.',
    USP_CREATE_STUDY_PLAN: 'Armar plan de estudios',
    USP_CREATING_STUDY_PLAN: 'Armando plan de estudios',
    USP_SAVE_CHANGES: 'Guardar cambios',
    USP_SAVING_CHANGES: 'Guardando cambios',
    USP_ENABLE_NOTIFICATIONS:
      'Quiero recibir notificaciones y seguimiento de mi plan de estudios',
    USP_CHANGES_SAVED_TITLE: '¡Tu plan de estudios se guardó con éxito!',
    USP_CHANGES_SAVED_DESCRIPTION:
      'Podrás visualizar tu progreso dentro de la pestaña de tu actividad. En el caso de que tus objetivos de estudios cambien, recuerda que puedes cambiar tu plan de estudios cuando quieras.',
    USP_BACK_TO_HOME: 'Regresar al home',
    USP_BACK_TO_PANEL: 'Regresar a mi panel',
    USP_BACK_TO_EDIT_VIEW: 'Seguir editando',
    // TARGET
    USP_TARGET_TITLE: '¿Cuál seria tu objetivo semanal de estudios en Crehana?',
    USP_TARGET_CHILL_LABEL: 'Chill',
    USP_TARGET_REGULAR_LABEL: 'Regular',
    USP_TARGET_INTENSIVE_LABEL: 'Intensivo',
    USP_TARGET_WEEKLY_TIME: '{{time}} min semanales',
    // FREQUENCY
    USP_FREQUENCY_TITLE: '¿Con cuánta frecuencia te gustaría aprender?',
    USP_FREQUENCY_ONE_LABEL: '1 vez',
    USP_FREQUENCY_TWO_LABEL: '2 veces',
    USP_FREQUENCY_THREE_LABEL: '3 veces',
    USP_FREQUENCY_FIVE_LABEL: '5 veces',
    USP_FREQUENCY_ALL_LABEL: 'Todos los días de la semana',
    USP_FREQUENCY_WEEKLY: 'a la semana',
    // Error messages
    USP_QUERY_ERROR: 'Ocurrió un error al obtener tu plan de estudios',
    USP_TRY_AGAIN: 'Intentar nuevamente',
    USP_CREATE_ERROR:
      'Ocurrió un error al crear tu plan de estudios. Por favor, inténtalo nuevamente.',
    USP_UPDATE_ERROR:
      'No se pudieron guardar tus cambios. Por favor, inténtalo nuevamente.',
  },
};

export default es;

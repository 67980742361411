import {
  StudyPlanFrequencyEnum,
  StudyPlanTargetEnum,
} from '@/shared/types/graphql/globals';

export const studyPlanTargets = [
  {
    title: 'USP_TARGET_CHILL_LABEL',
    subtitle: 'USP_TARGET_WEEKLY_TIME',
    subtitleTime: 30,
    value: StudyPlanTargetEnum.TARGET_CHILL,
    minutes: 30,
  },
  {
    title: 'USP_TARGET_REGULAR_LABEL',
    subtitle: 'USP_TARGET_WEEKLY_TIME',
    subtitleTime: 60,
    value: StudyPlanTargetEnum.TARGET_REGULAR,
    minutes: 60,
  },
  {
    title: 'USP_TARGET_INTENSIVE_LABEL',
    subtitle: 'USP_TARGET_WEEKLY_TIME',
    subtitleTime: 120,
    value: StudyPlanTargetEnum.TARGET_INTENSIVE,
    minutes: 120,
  },
];

export const studyPlanFrequencies = [
  {
    title: 'USP_FREQUENCY_ONE_LABEL',
    subtitle: 'USP_FREQUENCY_WEEKLY',
    value: StudyPlanFrequencyEnum.FREQUENCY_ONE,
  },
  {
    title: 'USP_FREQUENCY_TWO_LABEL',
    subtitle: 'USP_FREQUENCY_WEEKLY',
    value: StudyPlanFrequencyEnum.FREQUENCY_TWO,
  },
  {
    title: 'USP_FREQUENCY_THREE_LABEL',
    subtitle: 'USP_FREQUENCY_WEEKLY',
    value: StudyPlanFrequencyEnum.FREQUENCY_THREE,
  },
  {
    title: 'USP_FREQUENCY_FIVE_LABEL',
    subtitle: 'USP_FREQUENCY_WEEKLY',
    value: StudyPlanFrequencyEnum.FREQUENCY_FIVE,
  },
  {
    subtitle: 'USP_FREQUENCY_ALL_LABEL',
    value: StudyPlanFrequencyEnum.FREQUENCY_ALL,
  },
];

export const initialStatePageKey = 'userStudyPlan';
export const USER_STUDY_PLAN_TRANSLATION_KEY = 'user-study-plan';

var formatNumbers = function formatNumbers(num) {
  if (num < 10) {
    return "0".concat(num).slice(-2);
  }
  if (num > 999999) {
    return "".concat((num / 1000000).toFixed(1), "M");
  }
  if (num > 999) {
    return "".concat((num / 1000).toFixed(1), "k");
  }
  return num;
};
export default formatNumbers;
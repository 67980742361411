import type { TPageTranslations } from '@crehana/i18n';

import { DASHBOARD_ERROR_VIEW_TRANSLATION_KEY } from '../constants';
import type { DashboardErrorViewLocaleKeys } from '../types';

const localeEn: TPageTranslations<
  DashboardErrorViewLocaleKeys,
  typeof DASHBOARD_ERROR_VIEW_TRANSLATION_KEY
> = {
  [DASHBOARD_ERROR_VIEW_TRANSLATION_KEY]: {
    GENERAL_ERROR: 'An error occurred',
    TRY_AGAIN: 'Try again',
    INTERNET_CONNECTION_ERROR: 'Connection error occurred',
    RELOAD_PAGE: 'Reload the page',
  },
};

export default localeEn;

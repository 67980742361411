import { useCallback } from 'react';

import { Container } from '@crehana/ui';

import { useStudyPlanContext } from './context';
import ChangesSavedView from './sections/ChangesSavedView';
import CreateOrUpdateStudyPlan from './sections/CreateOrUpdateStudyPlan';

interface UserStudyPlanProps {
  onFinish: () => void;
  source?: 'home-user' | 'user-dashboard' | 'homepage' | 'user-panel';
  'data-testid'?: string;
}

const UserStudyPlan: React.FC<UserStudyPlanProps> = ({
  onFinish: onFinishProp,
  source = 'home-user',
  'data-testid': dataTestId,
}) => {
  const { currentView, changeCurrentView } = useStudyPlanContext();

  const onChangesSaved = useCallback(() => {
    changeCurrentView('changes-saved-view');
  }, [changeCurrentView]);

  const backToEditView = useCallback(() => {
    changeCurrentView('edit-view');
  }, [changeCurrentView]);

  const onFinish = useCallback(() => {
    backToEditView();
    onFinishProp();
  }, [backToEditView, onFinishProp]);

  return (
    <Container data-testid={dataTestId}>
      {currentView === 'changes-saved-view' ? (
        <ChangesSavedView
          backToEditView={backToEditView}
          onFinish={onFinish}
          source={source}
        />
      ) : (
        <CreateOrUpdateStudyPlan onChangesSaved={onChangesSaved} />
      )}
    </Container>
  );
};

export default UserStudyPlan;

import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CrehanaTFunction } from '@crehana/i18n';
import { UserDegreeSegmentationEnum } from '@crehana/ts-types';
import { Col, Container, Row } from '@crehana/ui';
import { useMe } from '@crehana/web';
import { StatusProjectEnum } from '@crehana/web/Graphql/types';

import { DashboardEmptyView, DashboardErrorView } from '@/shared/components';

import FilterHeader from './components/FilterHeader';
import ProjectCard from './components/ProjectCard';
import ProjectListSkeleton from './components/ProjectListSkeleton';
import { useMyProjects, useProjectsTranslation } from './hooks';
import { ProjectsLocaleKeys, ProjectsProps } from './types';

type ProjectFilterOption = {
  label: string;
  value: 'all' | 'certified' | 'in-progress';
  'data-testid': string;
};

const getFilterOptions = (
  t: CrehanaTFunction<ProjectsLocaleKeys>,
): ProjectFilterOption[] => [
  {
    label: t('UDP_FILTER_ALL_LABEL'),
    value: 'all',
    'data-testid': 'project-filter-all',
  },
  {
    label: t('UDP_FILTER_CERTIFIED_LABEL'),
    value: 'certified',
    'data-testid': 'project-filter-certified',
  },
  {
    label: t('UDP_FILTER_IN_PROGRESS_LABEL'),
    value: 'in-progress',
    'data-testid': 'project-filter-in-progress',
  },
];

export const Projects: FC<React.PropsWithChildren<ProjectsProps>> = ({
  amplitudePageName,
  enrollmentsCoursesRoutePath,
  enrollmentsDegreesRoutePath,
  enrollmentsRoute,
}) => {
  const history = useHistory();

  const { t } = useProjectsTranslation();

  const { me } = useMe();
  const hasActiveDegree =
    me?.userDegreeSegmentation ===
    UserDegreeSegmentationEnum.ACTIVATED_DEGREE_SEGMENTATION;
  const isMarketplaceUser =
    me?.organization?.myRole === 'high-education-member';

  const FILTER_OPTIONS = getFilterOptions(t);

  const [currentFilter, setCurrentFiler] = useState<ProjectFilterOption>(
    FILTER_OPTIONS.find(filter => filter.value === 'all')!,
  );
  const {
    loading,
    error,
    refetch,
    projects: projectsList,
  } = useMyProjects({
    currentActionState:
      currentFilter.value === 'certified'
        ? StatusProjectEnum.CERTIFIED
        : undefined,
  });

  const projects = (
    currentFilter.value === 'in-progress'
      ? projectsList.filter(p => !p.hasCertification)
      : projectsList
  ).sort(a => (a.course?.isFree ? 1 : -1));

  const renderContent = () => {
    if (loading) {
      return <ProjectListSkeleton data-testid="projects-skeleton" />;
    }
    if (error) {
      return (
        <DashboardErrorView
          error={t('UDP_PROJECTS_ERROR')}
          onRetry={() => {
            if (refetch) {
              refetch();
            }
          }}
          supportsDarkMode
          data-testid="projects-error-view"
        />
      );
    }
    if (!projects.length) {
      const goToCourses = () => {
        history.push(
          `${enrollmentsRoute}${
            hasActiveDegree
              ? enrollmentsDegreesRoutePath
              : enrollmentsCoursesRoutePath
          }`,
        );
      };

      const goToMyLearingPaths = () => {
        history.push(`${enrollmentsRoute}/my-courses/tracks/`);
      };

      const emptyViewProps = {
        all: {
          title: t('UDP_ALL_EMPTY_TITLE'),
          description: hasActiveDegree
            ? t('UDP_DEGREE_EMPTY_DESC')
            : t('UDP_ALL_EMPTY_DESC'),
          button: {
            label: hasActiveDegree
              ? t('UDP_DEGREE_EMPTY_BUTTON')
              : isMarketplaceUser
              ? t('UDP_ALL_EMPTY_BUTTON_USER_HE')
              : t('UDP_ALL_EMPTY_BUTTON'),
            onClick: isMarketplaceUser ? goToMyLearingPaths : goToCourses,
          },
        },
        certified: {
          title: t('UDP_CERTIFIED_EMPTY_TITLE'),
          description: hasActiveDegree
            ? t('UDP_DEGREE_EMPTY_DESC')
            : t('UDP_CERTIFIED_EMPTY_DESC'),
          button: {
            label: hasActiveDegree
              ? t('UDP_DEGREE_EMPTY_BUTTON')
              : t('UDP_CERTIFIED_EMPTY_BUTTON'),
            onClick: hasActiveDegree
              ? goToCourses
              : () => {
                  setCurrentFiler(
                    FILTER_OPTIONS.find(
                      filter => filter.value === 'in-progress',
                    )!,
                  );
                },
          },
        },
        'in-progress': {
          title: t('UDP_IN_PROGRESS_EMPTY_TITLE'),
          description: hasActiveDegree
            ? t('UDP_DEGREE_EMPTY_DESC')
            : t('UDP_IN_PROGRESS_EMPTY_DESC'),
          button: {
            label: hasActiveDegree
              ? t('UDP_DEGREE_EMPTY_BUTTON')
              : t('UDP_IN_PROGRESS_EMPTY_BUTTON'),
            onClick: goToCourses,
          },
        },
      };

      return (
        <DashboardEmptyView
          {...emptyViewProps[currentFilter.value]}
          supportsDarkMode
          data-testid="projects-empty-view"
        />
      );
    }
    return (
      <Row gutter={16} data-testid="projects-list">
        {projects.map(project => (
          <Col
            key={`project-${project.originalId}`}
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <ProjectCard amplitudePageName={amplitudePageName} {...project} />
          </Col>
        ))}
      </Row>
    );
  };

  const filterTitleKeys: { [key in string]: ProjectsLocaleKeys } = {
    all: 'UDP_FILTER_ALL_TITLE',
    certified: 'UDP_FILTER_CERTIFIED_TITLE',
    'in-progress': 'UDP_FILTER_IN_PROGRESS_TITLE',
  };

  return (
    <Container type="dashboard">
      {currentFilter.value === 'all' && !projects.length ? null : (
        <FilterHeader
          options={FILTER_OPTIONS}
          title={
            !loading && projects.length
              ? t(filterTitleKeys[currentFilter.value]!, {
                  count: projects.length,
                })
              : undefined
          }
          selected={currentFilter}
          onChange={option => {
            setCurrentFiler(option as ProjectFilterOption);
          }}
          data-testid="project-filters-dropdown"
        />
      )}
      {renderContent()}
    </Container>
  );
};

export default Projects;

import { useTranslation } from '@crehana/i18n';

import { DASHBOARD_ERROR_VIEW_TRANSLATION_KEY } from '../constants';
import { DashboardErrorViewLocalesBundle } from '../i18n';
import { DashboardErrorViewLocaleKeys } from '../types';

const useDashboardErrorViewTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<DashboardErrorViewLocaleKeys>(
      DASHBOARD_ERROR_VIEW_TRANSLATION_KEY,
    );

  addResourceBundle(DashboardErrorViewLocalesBundle);

  return translationContextValue;
};

export default useDashboardErrorViewTranslation;

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import { CustomNextImage } from '@crehana/next';
import { InverseButton } from '@crehana/ui';

import { RENEWAL_CARD_CTA_AMPLITUDE } from './constants';
import { RenewalCardGradientBackground } from './GradientBackground';
import { useRenewalCardTranslation } from './hooks';
import type { RenewalCardProps } from './types';
import { getMembershipDisplayName } from './utils';

const RenewalCard: React.FC<RenewalCardProps> = ({
  firstname,
  remainingDays,
  membershipTypeTag,
}) => {
  const { t } = useRenewalCardTranslation();

  const redirectToRenewal = () => {
    window.location.href = createUrlWithSourceQueryParams({
      url: '/renovacion/',
      source: RENEWAL_CARD_CTA_AMPLITUDE.SOURCE,
      sourceDetail: RENEWAL_CARD_CTA_AMPLITUDE.SOURCE_DETAIL,
    });
  };

  // TODO: review images
  return (
    <div className="relative min-h-360 flex flex-col rounded-5 overflow-hidden bg-base-lighter-24 shadow-card w-full z-0 md:my-4">
      {/** Image: Background */}
      <CustomNextImage
        src="https://crehana-vercel-images-prd.imgix.net/public/49c39743-f53f-4be8-82ba-26d0edec3a78-home_user_renewal_card_bg.jpg?auto=format&fit=clip"
        className="absolute left-0 top-0 w-full h-full min-w-200 z-0"
        objectFit="cover"
        width={200}
        height={200}
        alt="reward-notification"
      />

      {/** Image: Background decoration, oval left */}
      <CustomNextImage
        src="https://crehana-vercel-images-prd.imgix.net/public/9d3fe629-bc14-49ea-802e-56e8c6ddeaec-ellipse-decoration-left.png?auto=format&fit=clip"
        className="absolute left-0 top-0 mt-32 z-5 pointer-events-none"
        width={76}
        height={166}
        alt="ellipse decoration left"
      />

      {/** Image: Background decoration, oval right */}
      <CustomNextImage
        src="https://crehana-vercel-images-prd.imgix.net/public/34184b25-db58-49ad-a85a-3bba63c73d72-ellipse-decoration-right.png?auto=format&fit=clip"
        width={60}
        height={92}
        className="absolute top-0 right-0 z-5 mr-56 pointer-events-none"
        alt="ellipse decoration right"
      />

      <div css={RenewalCardGradientBackground} />

      <div className="p-16 text-center text-white mt-auto z-5">
        <h3 className="mb-16 px-8 font-subtitle2">
          {t(
            firstname
              ? 'CARD_RENEWAL_TITLE_WITH_NAME'
              : 'CARD_RENEWAL_TITLE_WITHOUT_NAME',
            {
              interpolation: { firstname },
            },
          )}
        </h3>
        <p className="mb-16 px-2 font-body4">
          {t('CARD_RENEWAL_MESSAGE', {
            interpolation: {
              remainingDays,
              membershipType: getMembershipDisplayName(membershipTypeTag),
            },
          })}
        </p>
        <InverseButton
          full
          isDark
          isWhite
          size="m"
          label={t('CARD_RENEWAL_CTA')}
          onClick={redirectToRenewal}
        />
      </div>
    </div>
  );
};

export default RenewalCard;

import { FILTER_HEADER_TRANSLATION_KEY } from '../constants';

const localeEs = {
  [FILTER_HEADER_TRANSLATION_KEY]: {
    UD_FILTER_LABEL: 'Filtrar por',
    UD_ORDER_BY: 'Ordenar por',
  },
};

export default localeEs;

import { useTranslation } from '@crehana/i18n';

import { PROJECTS_TRANSLATION_KEY } from '../constants';
import { ProjectsLocalesBundle } from '../i18n';
import type { ProjectsLocaleKeys } from '../types';

const useProjectsTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<ProjectsLocaleKeys>(PROJECTS_TRANSLATION_KEY);

  addResourceBundle(ProjectsLocalesBundle);

  return translationContextValue;
};

export default useProjectsTranslation;

import { RENEWAL_CARD_TRANSLATION_KEY } from '../constants';

const localePt = {
  [RENEWAL_CARD_TRANSLATION_KEY]: {
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, parabéns pelas conquistas deste ano!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME: 'Parabéns pelas conquistas deste ano!',
    CARD_RENEWAL_MESSAGE:
      'Você tem {{remainingDays}} dias a mais para aproveitar sua assinatura de {{membershipType}}. Vamos por mais 1 ano? Renove e continue crescendo.',
    CARD_RENEWAL_CTA: 'Quero renovar',
  },
};

export default localePt;

import cn from 'clsx';

import { Button } from '@crehana/compass.ui/button';
import Megaphone from '@crehana/icons/outline/Megaphone';
import { colors } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

export interface DashboardEmptyViewProps {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  button?: {
    label: string;
    href?: string;
    onClick?: () => void;
    'data-testid'?: string;
  };
  supportsDarkMode?: boolean;
  'data-testid'?: string;
}

const { primaryMain, secondaryLight } = colors;

const DashboardEmptyView: React.FC<DashboardEmptyViewProps> = ({
  className,
  title,
  description,
  button,
  supportsDarkMode,
  'data-testid': dataTestId,
}) => {
  const { isDark } = useUITheme();
  const dark = supportsDarkMode && isDark;
  const textColor = dark ? 'text-white' : 'text-base-main';

  return (
    <div
      className={cn(
        'tw-w-full tw-rounded-1',
        dark ? 'bg-base-light-dark-mode' : 'bg-base-lighter-16',
        className,
      )}
      data-testid={dataTestId}
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-py-1.6 tw-px-1.6 sm:tw-py-3.2 sm:tw-px-0">
        <div
          className={cn(
            'tw-w-6.4 tw-h-6.4 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-mb-2.4',
            isDark ? 'bg-base-lighter-56' : 'bg-primary-lighter-12',
          )}
        >
          <Megaphone size={32} color={isDark ? secondaryLight : primaryMain} />
        </div>
        {typeof title === 'string' ? (
          <h4 className={`font-h4 whitespace-pre-line ${textColor}`}>
            {title}
          </h4>
        ) : (
          title
        )}
        {typeof description === 'string' ? (
          <p
            className={cn(
              'tw-font-body3 tw-whitespace-pre-line',
              title && 'tw-mt-1.6',
              textColor,
            )}
          >
            {description}
          </p>
        ) : (
          description
        )}
        {button && (
          <Button
            type="button"
            className={cn(
              `w-full sm:w-auto`,
              title || description ? 'mt-16' : '',
            )}
            size="lg"
            {...button}
          >
            {button.label}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DashboardEmptyView;

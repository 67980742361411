import { DASHBOARD_ERROR_VIEW_TRANSLATION_KEY } from '../constants';

const localeEs = {
  [DASHBOARD_ERROR_VIEW_TRANSLATION_KEY]: {
    GENERAL_ERROR: 'Ocurrió un error',
    TRY_AGAIN: 'Vuelve a intentarlo',
    INTERNET_CONNECTION_ERROR: 'Ocurrió un error de conexión',
    RELOAD_PAGE: 'Recargar la página',
  },
};

export default localeEs;

import { FC } from 'react';

import { Container } from '@crehana/ui';
import { Loadable, useUITheme } from '@crehana/web';
import StudyPlanMetricsSkeleton from '@crehana/web/StudyPlan/components/StudyPlanMetricsSkeleton';

import { DashboardErrorView } from '@/shared/components';

import { StudyPlanProps } from './types';

const LazyStudyPlan: FC<React.PropsWithChildren<StudyPlanProps>> = props => {
  const { isDark } = useUITheme();

  return (
    <Loadable
      Loader={
        <Container type="dashboard">
          <StudyPlanMetricsSkeleton isDark={isDark} />
        </Container>
      }
      ErrorFallback={
        <Container type="dashboard">
          <DashboardErrorView defaultConnectionError supportsDarkMode />
        </Container>
      }
      module={() =>
        import(
          /* webpackChunkName: "user-dashboard-study-plan" */ './StudyPlan'
        )
      }
      isDark={isDark}
      {...props}
    />
  );
};

export default LazyStudyPlan;

import { FC } from 'react';

import { Loadable } from '@crehana/web';

import usePhoneNumberState from './hooks/usePhoneNumberState';
import { PhoneState as _PhoneState, PhoneInputType } from './types';

const LazyPhoneInput: FC<React.PropsWithChildren<PhoneInputType>> = props => (
  <Loadable
    module={() =>
      import(
        /* webpackChunkName: "shared-react-phone-input" */
        './PhoneInput'
      )
    }
    Loader={
      <>
        <div className="w-1/3 h-20 mb-8 bg-gray-dark-16 rounded-5" />
        <div className="w-full h-56 bg-gray-dark-16 rounded-5" />
      </>
    }
    {...props}
  />
);

export { usePhoneNumberState };

export type PhoneState = _PhoneState;

export default LazyPhoneInput;

import { FC } from 'react';
import * as React from 'react';

import cn from 'clsx';

import { CuiOptionType, Dropdown } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import useProjectsTranslation from '../hooks/useProjectsTranslation';

interface FiltersProps {
  className?: string;
  title?: string;
  options: CuiOptionType[];
  selected: CuiOptionType;
  onChange: (selected: CuiOptionType) => void;
  'data-testid'?: string;
}

export const FilterHeader: FC<React.PropsWithChildren<FiltersProps>> = ({
  className,
  title,
  options,
  selected,
  onChange,
  'data-testid': dataTestId,
}) => {
  const { t } = useProjectsTranslation();

  const { isDark } = useUITheme();

  return (
    <div
      className={cn(
        'flex flex-col mb-16 sm:mb-24',
        title ? 'md:flex-row-reverse md:items-center md:justify-between' : '',
        className,
      )}
    >
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-end">
        <span className="font-label text-base-lighter mb-8 sm:mb-0 sm:mr-24">
          {t('UD_FILTER_LABEL')}
        </span>
        <Dropdown
          onSelected={selectedOption => {
            if (selectedOption) {
              onChange(selectedOption);
            }
          }}
          options={options}
          selected={selected}
          dark={isDark}
          size={options.length}
          data-testid={dataTestId}
        />
      </div>
      {title && <span className="font-subtitle2 mt-24 md:mt-0">{title}</span>}
    </div>
  );
};

export default React.memo(FilterHeader);

import { createContext } from 'react';

import {
  StudyPlanFrequencyEnum,
  StudyPlanTargetEnum,
} from '@/shared/types/graphql/globals';

import { StudyPlan, StudyPlanState } from '../types';

export interface StudyPlanContextType
  extends Pick<StudyPlanState, 'mutationStatus'> {
  currentView: 'edit-view' | 'changes-saved-view';
  changeCurrentView: (view: 'edit-view' | 'changes-saved-view') => void;
  loading: boolean;
  studyPlan: StudyPlan;
  queryError: boolean;
  onRefetch: () => void;
  isUpdateMode: boolean;
  onTargetChange: (newTarget: StudyPlanTargetEnum) => void;
  onFrequencyChange: (newFrequency: StudyPlanFrequencyEnum) => void;
  onNotificationsEnabledChange: (enabled: boolean) => void;
  onSave: () => void;
}

export const defaultContext: StudyPlanContextType = {
  currentView: 'edit-view',
  changeCurrentView: () => {},
  loading: false,
  studyPlan: {
    target: undefined,
    frequency: undefined,
    notificationsEnabled: false,
  },
  queryError: false,
  onRefetch: () => {},
  isUpdateMode: false,
  onTargetChange: () => {},
  onFrequencyChange: () => {},
  onNotificationsEnabledChange: () => {},
  onSave: () => {},
  mutationStatus: { status: 'initial', errorMessage: '' },
};

const StudyPlanContext = createContext<StudyPlanContextType>(defaultContext);

export default StudyPlanContext;

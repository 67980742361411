import { useState } from 'react';

import { LightMenu as LightMenuComponent } from '@crehana/web';

import MainMenuWrapper from './MainMenuWrapper';
import { MainMenuWithTopBannerProps } from './types';

const LightMenu: React.FC<
  React.PropsWithChildren<MainMenuWithTopBannerProps>
> = props => {
  const [isTopBannerOpen, setIsTopBannerOpen] = useState(false);

  return (
    <MainMenuWrapper
      countryPrefix={props.countryPrefix}
      amplitudePageName={props.amplitudePageName}
      onToggleTopBanner={setIsTopBannerOpen}
      position={props.position}
      {...props.topBannerProps}
    >
      <LightMenuComponent topBannerIsOpen={isTopBannerOpen} {...props} />
    </MainMenuWrapper>
  );
};

export default LightMenu;

import { useState } from 'react';

import { MainMenu as MainMenuComponent } from '@crehana/web';

import MainMenuWrapper from './MainMenuWrapper';
import type { MainMenuWithTopBannerProps } from './types';

const MainMenu: React.FC<
  React.PropsWithChildren<MainMenuWithTopBannerProps>
> = props => {
  const [isTopBannerOpen, setIsTopBannerOpen] = useState(false);

  return (
    <MainMenuWrapper
      countryPrefix={props.countryPrefix}
      amplitudePageName={props.amplitudePageName}
      onToggleTopBanner={setIsTopBannerOpen}
      position={props.position}
      {...props.topBannerProps}
    >
      <MainMenuComponent topBannerIsOpen={isTopBannerOpen} {...props} />
    </MainMenuWrapper>
  );
};

export default MainMenu;

import { forwardRef } from 'react';

import { cn } from '@crehana/compass.ui/v2';

type ButtonDropdownProps = {
  active?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const ButtonDropdown = forwardRef<HTMLButtonElement, ButtonDropdownProps>(
  (props, ref) => {
    const { active, children, className, ...propsRest } = props;

    return (
      <button
        type="button"
        className={cn(
          // Size and spacing
          'tw-flex tw-items-center tw-gap-1',
          'tw-px-1.2 tw-py-0.4',
          // Text
          '!tw-text-1.2 tw-font-subtitle5',
          'tw-text-neutral-light-800 dark:tw-text-neutral-dark-100',
          // Border
          'tw-border tw-border-solid tw-rounded-0.8',
          'tw-border-neutral-light-500 dark:tw-border-neutral-dark-600',
          active &&
            'tw-border-primary-light-500 dark:tw-border-primary-dark-500 tw-text-primary-light-500 dark:tw-text-primary-dark-500',
          className,
        )}
        ref={ref}
        {...propsRest}
      >
        {children}
      </button>
    );
  },
);
ButtonDropdown.displayName = 'ButtonDropdown';

export default ButtonDropdown;

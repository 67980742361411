import { FC } from 'react';

import Filter from '@crehana/compass.icons.outline/Filter';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@crehana/compass.ui/sheet';

import { useFilterHeaderTranslation } from '../hooks';
import { ButtonFilter, ListFilterOptions } from '../ui';

type TFilterBySheetProps = {
  items: {
    value: string;
    type?: string;
    label: string;
  }[];
  selected: string;
  onSelect: (value: string) => void;
};

const FilterBySheet: FC<TFilterBySheetProps> = ({
  items,
  selected,
  onSelect,
}) => {
  const { t } = useFilterHeaderTranslation();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <ButtonFilter>
          <Filter size={16} className="tw-fill-current" />
          <span>{t('UD_FILTER_LABEL')}</span>
        </ButtonFilter>
      </SheetTrigger>
      <SheetContent side="bottom" className="tw-rounded-t-1.6 tw-p-2.4">
        <SheetHeader>
          <SheetTitle className="tw-text-left tw-text-1.8">
            {t('UD_FILTER_LABEL')}
          </SheetTitle>
          <SheetDescription>
            <SheetClose asChild>
              <div>
                <ListFilterOptions
                  items={items}
                  selectedValue={selected}
                  onChange={onSelect}
                />
              </div>
            </SheetClose>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default FilterBySheet;

import { ChangeEvent, FC, useEffect } from 'react';

import { css } from 'styled-components';

import { Checkbox, Col, Loader, PrimaryButton, Row } from '@crehana/ui';
import { useMe, useUITheme } from '@crehana/web';

import OptionButton from '../components/OptionButton';
import { studyPlanFrequencies, studyPlanTargets } from '../constants';
import { useStudyPlanContext } from '../context';
import useUserStudyPlanTranslations from '../hooks/useUserStudyPlanTranslations';
import type { UserStudyPlanTranslationKeys } from '../i18n';

interface CreateOrUpdateStudyPlanProps {
  onChangesSaved: () => void;
}

const CreateOrUpdateStudyPlan: FC<CreateOrUpdateStudyPlanProps> = ({
  onChangesSaved,
}) => {
  const { t } = useUserStudyPlanTranslations();

  const { isDark } = useUITheme();
  const { me } = useMe();

  const {
    loading,
    studyPlan: { target, frequency, notificationsEnabled },
    queryError,
    onRefetch,
    isUpdateMode,
    onTargetChange,
    onFrequencyChange,
    onNotificationsEnabledChange,
    onSave,
    mutationStatus: { status, errorMessage },
  } = useStudyPlanContext();

  useEffect(() => {
    if (status === 'success') {
      onChangesSaved();
    }
  }, [status]);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    onNotificationsEnabledChange(e.target.checked);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Row data-testid="study-plan-loader">
          <Col className="flex justify-center">
            <Loader white={isDark} />
          </Col>
        </Row>
      );
    }
    if (queryError) {
      return (
        <Row data-testid="study-plan-error">
          <Col className="flex flex-col items-center text-center">
            <p className="mb-24 font-subtitle3">{t('USP_QUERY_ERROR')}</p>
            <PrimaryButton
              label={t('USP_TRY_AGAIN')}
              onClick={onRefetch}
              darkMode={isDark}
            />
          </Col>
        </Row>
      );
    }
    let buttonLabel = isUpdateMode
      ? t('USP_SAVE_CHANGES')
      : t('USP_CREATE_STUDY_PLAN');

    if (status === 'loading') {
      buttonLabel = isUpdateMode
        ? t('USP_SAVING_CHANGES')
        : t('USP_CREATING_STUDY_PLAN');
    }

    return (
      <>
        <Row className="mb-40 sm:mb-48" nowrap data-testid="study-plan-form">
          <Col className="flex flex-col">
            <span className="mb-24 text-center font-subtitle3">
              {t('USP_TARGET_TITLE')}
            </span>
            <div className="flex flex-col sm:flex-row sm:justify-center">
              {studyPlanTargets.map((targ, i) => (
                <div
                  key={`target-${targ.value}`}
                  className={`sm:px-12${
                    i < studyPlanTargets.length - 1 ? ' mb-16 sm:mb-0' : ''
                  }`}
                >
                  <OptionButton
                    className="justify-between px-16 py-16 sm:flex-col sm:justify-center sm:px-0 sm:py-12"
                    // @ts-ignore
                    css={css`
                      ${({ theme }) => theme.breakpoints.up('sm')} {
                        width: 172px;
                      }
                    `}
                    title={t(targ.title as UserStudyPlanTranslationKeys)}
                    subtitle={t(targ.subtitle as UserStudyPlanTranslationKeys, {
                      interpolation: { time: targ.subtitleTime },
                    })}
                    selected={target === targ.value}
                    onClick={() => {
                      if (status !== 'loading') {
                        onTargetChange(targ.value);
                      }
                    }}
                    data-testid={`target-${targ.value}`}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="mb-40 sm:mb-48" nowrap>
          <Col className="flex flex-col">
            <span className="mb-24 text-center font-subtitle3">
              {t('USP_FREQUENCY_TITLE')}
            </span>
            <div className="flex flex-row flex-wrap -mx-4 -my-4 sm:my-0 sm:-mx-12 sm:m-0 sm:justify-center">
              {studyPlanFrequencies.map(freq => (
                <div
                  key={`frequency-${freq.value}`}
                  className="w-1/2 px-4 py-4 sm:w-auto sm:py-0 sm:px-12"
                >
                  <OptionButton
                    className="flex-col justify-center h-full py-12 sm:w-120"
                    title={t(freq.title as UserStudyPlanTranslationKeys)}
                    subtitle={t(freq.subtitle as UserStudyPlanTranslationKeys)}
                    selected={frequency === freq.value}
                    onClick={() => {
                      if (status !== 'loading') {
                        onFrequencyChange(freq.value);
                      }
                    }}
                    data-testid={`frequency-${freq.value}`}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row nowrap>
          <Col className="flex flex-col sm:items-center">
            {errorMessage && (
              <p
                className="mb-16 text-center font-body3"
                data-testid="create-or-update-error"
              >
                {errorMessage}
              </p>
            )}
            <PrimaryButton
              label={buttonLabel}
              size="l"
              onClick={onSave}
              disabled={status === 'loading' || !target || !frequency}
              darkMode={isDark}
              data-testid="save-study-plan"
            />
            {me?.isPremiumMember && (
              <Checkbox
                className="mt-16"
                label={t('USP_ENABLE_NOTIFICATIONS')}
                checked={notificationsEnabled}
                onChange={onCheckboxChange}
                darkMode={isDark}
                disabled={status === 'loading'}
                small
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row
        className="justify-center mb-40 text-center sm:mb-48"
        nowrap
        data-testid="study-plan-edit-view"
      >
        <Col xs={12} sm={10} md={8}>
          <h1 className="mb-16 font-h3">{t('USP_STUDY_PLAN_TITLE')}</h1>
          <p
            className={`font-body3 ${
              isDark ? 'text-base-lighter' : 'text-gray-dark'
            }`}
          >
            {t('USP_STUDY_PLAN_DESCRIPTION')}
          </p>
        </Col>
      </Row>
      {renderContent()}
    </>
  );
};

export default CreateOrUpdateStudyPlan;

import { lazy, Suspense } from 'react';

import type { DialogGetPhoneContentProps } from './DialogGetPhoneContent';

const LazyDialogGetPhoneContent = lazy(
  () =>
    import(
      /* webpackChunkName: "dialog-get-phone-content" */ './DialogGetPhoneContent'
    ),
);

const DialogGetPhoneContent: React.FC<
  React.PropsWithChildren<DialogGetPhoneContentProps>
> = props => {
  return (
    <Suspense fallback={null}>
      <LazyDialogGetPhoneContent {...props} />
    </Suspense>
  );
};

export default DialogGetPhoneContent;

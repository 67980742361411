import toDate from "./toDate";
var calculateTimeDifference = function calculateTimeDifference(date, translation) {
  var now = new Date();
  var then = typeof date === 'string' ? toDate(date) : date;
  var millis = Math.abs(now.getTime() - then.getTime());
  if (millis < 1000 * 60) {
    return translation.SECONDS_AGO;
  }
  var mins = Math.ceil(millis / (1000 * 60));
  if (mins === 1) {
    return translation.A_MINUTE_AGO;
  }
  if (mins < 60) {
    return translation.MINUTES_AGO(mins);
  }
  var hours = Math.ceil(mins / 60);
  if (hours === 1) {
    return translation.AN_HOUR_AGO;
  }
  if (hours < 24) {
    return translation.HOURS_AGO(hours);
  }
  var days = Math.ceil(hours / 24);
  if (days === 1) {
    return translation.A_DAY_AGO;
  }
  if (days < 30) {
    return translation.DAYS_AGO(days);
  }
  var months = Math.ceil(days / 30);
  if (months === 1) {
    return translation.A_MONTH_AGO;
  }
  if (months < 12) {
    return translation.MONTHS_AGO(months);
  }
  var years = Math.ceil(months / 12);
  if (years === 1) {
    return translation.A_YEAR_AGO;
  }
  return translation.YEARS_AGO(years);
};
export default calculateTimeDifference;
import { CourseEnrollmentCardSkeleton, useUITheme } from '@crehana/web';

type TCourseListSkeletonProps = {
  'data-testid'?: string;
};

const CourseListSkeleton: React.FC<TCourseListSkeletonProps> = ({
  'data-testid': testId,
}) => {
  const { isDark } = useUITheme();

  return (
    <>
      <div
        data-testid={testId}
        className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-2"
      >
        {Array.from({ length: 8 }).map((_, i) => (
          <CourseEnrollmentCardSkeleton
            key={`course-skeleton-${i * 1}`}
            isDark={isDark}
          />
        ))}
      </div>
    </>
  );
};

export default CourseListSkeleton;

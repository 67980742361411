import { Container } from '@crehana/ui';
import Loadable from '@crehana/web/Loadable';

import { DashboardErrorView } from '@/shared/components';

import SavedCoursesSkeleton from './components/SavedCoursesSkeleton';
import { SavedCoursesProps } from './types';

const LazySavedCourses = (props: SavedCoursesProps) => (
  <Loadable
    Loader={
      <Container type="dashboard">
        <SavedCoursesSkeleton isDark={props.isDark} />
      </Container>
    }
    ErrorFallback={
      <Container type="dashboard">
        <DashboardErrorView defaultConnectionError supportsDarkMode />
      </Container>
    }
    module={() =>
      import(
        /* webpackChunkName: "user-dashboard-saved-courses" */ './SavedCourses'
      )
    }
    {...props}
  />
);

export default LazySavedCourses;

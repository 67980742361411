import { CUSTOM_FORMAT } from "./constants";
import toDate from "./toDate";
import toString from "./toString";

/**
 * Receives a string or Date object and returns it in the given format.
 *
 * @param {string | Date} dateToFormat - Date object or date as string
 * @param {string} fmt - Format expected
 * @param {inputFmt} fmt - If you provide a string, you can specify the input format, otherwise it will be assumed to have the FULL_FORMAT
 * @param {'es' | 'pt'} fmt - You can force the target language. By default, it will use the current language key
 *
 * @example
 * const fullDate = '2017-12-21T22:09:55.617047+00:00';
 * const dateWithCustomFormat = '2017/12/21';
 *
 * console.log(format(fullDate, 'MMM D, YYYY')); // Dic 21, 2017
 * console.log(format(sateWithCustomFormat, 'MMM D, YYYY', 'YYYY/MM/DD')); // Dic 21, 2017
 */
var format = function format(dateToFormat, fmt, inputFmt) {
  var lang = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'es';
  var date = typeof dateToFormat === 'string' ? toDate(dateToFormat, inputFmt) : dateToFormat;
  if (date && typeof date !== 'boolean') {
    return toString(date, fmt || CUSTOM_FORMAT, lang);
  }
  return '';
};
export default format;
import type { TPageTranslations } from '@crehana/i18n';

import { PROJECTS_TRANSLATION_KEY } from '../constants';
import type { ProjectsLocaleKeys } from '../types';

const localeEn: TPageTranslations<
  ProjectsLocaleKeys,
  typeof PROJECTS_TRANSLATION_KEY
> = {
  [PROJECTS_TRANSLATION_KEY]: {
    // Filters
    UDP_FILTER_ALL_TITLE: 'You have {{count}} project in total',
    UDP_FILTER_ALL_TITLE_plural: 'You have {{count}} projects in total',
    UDP_FILTER_CERTIFIED_TITLE: 'You have {{count}} certified project',
    UDP_FILTER_CERTIFIED_TITLE_plural: 'You have {{count}} certified projects',
    UDP_FILTER_IN_PROGRESS_TITLE: 'You have {{count}} project in progress',
    UDP_FILTER_IN_PROGRESS_TITLE_plural:
      'You have {{count}} projects in progress',
    UDP_FILTER_ALL_LABEL: 'All',
    UDP_FILTER_CERTIFIED_LABEL: 'Certified',
    UDP_FILTER_IN_PROGRESS_LABEL: 'In Progress',
    // Empty view
    UDP_ALL_EMPTY_TITLE: 'You have not started any project yet',
    UDP_ALL_EMPTY_DESC:
      'Make progress in your courses and apply what you learn.',
    UDP_ALL_EMPTY_BUTTON: 'Go to my courses',
    UDP_ALL_EMPTY_BUTTON_USER_HE: 'Go to my paths',
    UDP_CERTIFIED_EMPTY_TITLE: 'You do not have any certified projects yet',
    UDP_CERTIFIED_EMPTY_DESC:
      'Complete all the deliverables of your projects and request feedback from our mentors to obtain your diploma.',
    UDP_CERTIFIED_EMPTY_BUTTON: 'View projects in progress',
    UDP_IN_PROGRESS_EMPTY_TITLE: 'You currently have no projects in progress',
    UDP_IN_PROGRESS_EMPTY_DESC:
      'Remember that in order to obtain a diploma at Crehana, you must not only finish your classes, but also pass your final project. To start, you must go to the "Your final project" section within your course, where you can find all the project specifications to be delivered.',
    UDP_IN_PROGRESS_EMPTY_BUTTON: 'Go to my courses',
    UDP_DEGREE_EMPTY_DESC:
      'Remember that as you progress with your courses, you must mandatory complete the partial projects and the final project of your MicroDegree.',
    UDP_DEGREE_EMPTY_BUTTON: 'Go to my MicroDegree',
    // Error view
    UDP_PROJECTS_ERROR: 'An error occurred while retrieving your projects',
    // Project card
    UDP_EMPTY_TITLE: 'Untitled Project',
    UDP_FREE_COURSE: 'Free Course',
    UDP_UPDATED_AT: 'Last updated {{date}}',
    UDP_PUBLISHED_AT: 'Published on {{date}}',
    UDP_EDIT_PROJECT: 'Edit your project',
    UDP_GO_TO_PROJECT: 'Go to project',
    // Filter header
    UD_FILTER_LABEL: 'Filter by',
  },
};

export default localeEn;

import { Container, Loader } from '@crehana/ui';
import { Loadable, useUITheme } from '@crehana/web';

import DashboardErrorView from '@/shared/components/DashboardErrorView';

import { MyNotesProps } from './types';

const LazyClassNotes: React.FC<
  React.PropsWithChildren<Omit<MyNotesProps, 'dark'>>
> = props => {
  const { isDark } = useUITheme();

  return (
    <Loadable
      Loader={
        <Container type="dashboard" className="flex justify-center">
          <Loader isWhite={isDark} />
        </Container>
      }
      ErrorFallback={
        <Container type="dashboard">
          <DashboardErrorView defaultConnectionError supportsDarkMode />
        </Container>
      }
      module={() =>
        import(
          /* webpackChunkName: "user-dashboard-classnotes" */ './containers/MyNotes'
        )
      }
      dark={isDark}
      {...props}
    />
  );
};

export default LazyClassNotes;

import { Skeleton } from '@crehana/compass.ui';
import { CourseEnrollmentCardSkeleton, useUITheme } from '@crehana/web';

type TCourseListSkeletonProps = {
  'data-testid'?: string;
};

const CourseListSkeleton: React.FC<TCourseListSkeletonProps> = ({
  'data-testid': testId,
}) => {
  const { isDark } = useUITheme();

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-1.6">
        <Skeleton className="tw-w-14.4 tw-h-2.4 tw-rounded-0.4" animated />
        <div className="tw-flex tw-items-center">
          <Skeleton
            className="tw-w-7.2 tw-h-2.4 tw-rounded-0.4 tw-mr-2.4"
            animated
          />
          <Skeleton className="tw-w-28 tw-h-5.6 tw-rounded-0.4" animated />
        </div>
      </div>
      <div
        data-testid={testId}
        className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-2"
      >
        {Array.from({ length: 8 }).map((_, i) => (
          <CourseEnrollmentCardSkeleton
            key={`course-skeleton-${i * 1}`}
            isDark={isDark}
          />
        ))}
      </div>
    </>
  );
};

export default CourseListSkeleton;

import { useEffect, useReducer, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { useMe } from '@crehana/web';

import {
  StudyPlanFrequencyEnum,
  StudyPlanTargetEnum,
} from '@/shared/types/graphql/globals';

import CREATE_OR_UPDATE_STUDY_PLAN_MUTATION from '../graphql/CreateOrUpdateStudyPlan.graphql';
import GET_STUDY_PLAN_QUERY from '../graphql/GetUserStudyPlan.graphql';
import type {
  CreateOrUpdateStudyPlan as CreateOrUpdateStudyPlanResponse,
  CreateOrUpdateStudyPlanVariables,
} from '../graphql/types/CreateOrUpdateStudyPlan';
import { GetUserStudyPlan as GetStudyPlanResponse } from '../graphql/types/GetUserStudyPlan';
import useUserStudyPlanTranslations from '../hooks/useUserStudyPlanTranslations';
import { StudyPlanAction, StudyPlanState } from '../types';
import StudyPlanContext from './StudyPlanContext';

const reducer = (
  state: StudyPlanState,
  action: StudyPlanAction,
): StudyPlanState => {
  switch (action.type) {
    case 'SET_INITIAL_STUDY_PLAN':
      return { ...state, ...action.value };
    case 'CHANGE_TARGET':
      return { ...state, target: action.target };
    case 'CHANGE_FREQUENCY':
      return { ...state, frequency: action.frequency };
    case 'CHANGE_NOTIFICATIONS_ENABLED':
      return { ...state, notificationsEnabled: action.notificationsEnabled };
    case 'CHANGE_MUTATION_STATUS':
      return {
        ...state,
        mutationStatus: {
          status: action.status,
          errorMessage: action.status === 'error' ? action.errorMessage : '',
        },
      };
    case 'RESET_MUTATION_STATUS':
      return {
        ...state,
        mutationStatus: { status: 'initial', errorMessage: '' },
      };
    default:
      return state;
  }
};

const StudyPlanContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { t } = useUserStudyPlanTranslations();

  const { me } = useMe();
  const [currentView, setCurrentView] = useState<
    'edit-view' | 'changes-saved-view'
  >('edit-view');

  const changeCurrentView = (view: 'edit-view' | 'changes-saved-view') => {
    setCurrentView(view);
  };

  const [studyPlanState, dispatch] = useReducer<
    (state: StudyPlanState, action: StudyPlanAction) => StudyPlanState
  >(reducer, {
    target: undefined,
    frequency: undefined,
    notificationsEnabled: !!me?.isPremiumMember,
    mutationStatus: { status: 'initial', errorMessage: '' },
  });

  const { loading, data, error, refetch, updateQuery } =
    useQuery<GetStudyPlanResponse>(GET_STUDY_PLAN_QUERY, {
      notifyOnNetworkStatusChange: true,
    });
  const [createOrUpdateMutation] = useMutation<
    CreateOrUpdateStudyPlanResponse,
    CreateOrUpdateStudyPlanVariables
  >(CREATE_OR_UPDATE_STUDY_PLAN_MUTATION);
  const isUpdateMode = !!data?.userStudyPlan;

  useEffect(() => {
    if (!loading && data?.userStudyPlan) {
      dispatch({
        type: 'SET_INITIAL_STUDY_PLAN',
        value: {
          target: data.userStudyPlan.targetEnum,
          frequency: data.userStudyPlan.frequencyEnum,
          notificationsEnabled:
            !!data.userStudyPlan.user?.settings?.studyPlanAllowNotification,
        },
      });
    }
  }, [loading, data]);

  useEffect(() => {
    dispatch({ type: 'RESET_MUTATION_STATUS' });
  }, [currentView]);

  const onTargetChange = (newTarget: StudyPlanTargetEnum) => {
    dispatch({ type: 'CHANGE_TARGET', target: newTarget });
  };

  const onFrequencyChange = (newFrequency: StudyPlanFrequencyEnum) => {
    dispatch({ type: 'CHANGE_FREQUENCY', frequency: newFrequency });
  };

  const onNotificationsEnabledChange = (enabled: boolean) => {
    dispatch({
      type: 'CHANGE_NOTIFICATIONS_ENABLED',
      notificationsEnabled: enabled,
    });
  };

  const onSave = () => {
    const defaultError = isUpdateMode
      ? t('USP_UPDATE_ERROR')
      : t('USP_CREATE_ERROR');

    dispatch({ type: 'CHANGE_MUTATION_STATUS', status: 'loading' });
    createOrUpdateMutation({
      variables: {
        target: studyPlanState.target!,
        frequency: studyPlanState.frequency!,
        allowNotifications: studyPlanState.notificationsEnabled,
      },
    })
      .then(res => {
        if (res.data?.createOrUpdateStudyPlan?.success) {
          updateQuery(prevData => ({
            ...prevData,
            ...res.data!.createOrUpdateStudyPlan!.studyPlan!,
          }));
          dispatch({ type: 'CHANGE_MUTATION_STATUS', status: 'success' });
        } else {
          dispatch({
            type: 'CHANGE_MUTATION_STATUS',
            status: 'error',
            errorMessage: defaultError,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'CHANGE_MUTATION_STATUS',
          status: 'error',
          errorMessage: defaultError,
        });
      });
  };

  return (
    <StudyPlanContext.Provider
      value={{
        currentView,
        changeCurrentView,
        loading,
        studyPlan: {
          target: studyPlanState.target,
          frequency: studyPlanState.frequency,
          notificationsEnabled: studyPlanState.notificationsEnabled,
        },
        queryError: !!error || !data,
        onRefetch: () => {
          refetch();
        },
        isUpdateMode,
        onTargetChange,
        onFrequencyChange,
        onNotificationsEnabledChange,
        onSave,
        mutationStatus: studyPlanState.mutationStatus,
      }}
    >
      {children}
    </StudyPlanContext.Provider>
  );
};

export default StudyPlanContextProvider;

import { useTranslation } from '@crehana/i18n';

import { FILTER_HEADER_TRANSLATION_KEY } from '../constants';
import { FilterHeaderLocalesBundle } from '../i18n';
import { FilterHeaderLocaleKeys } from '../types';

const useFilterHeaderTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<FilterHeaderLocaleKeys>(FILTER_HEADER_TRANSLATION_KEY);

  addResourceBundle(FilterHeaderLocalesBundle);

  return translationContextValue;
};

export default useFilterHeaderTranslation;

import { useContext } from 'react';

import StudyPlanContext from './StudyPlanContext';

const useStudyPlanContext = () => {
  const context = useContext(StudyPlanContext);

  if (context === undefined) {
    throw new Error(
      'useStudyPlanContext must be used within a StudyPlanContextProvider',
    );
  }

  return context;
};

export default useStudyPlanContext;

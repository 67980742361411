import { useState } from 'react';

import { PhoneState } from '../types';

const usePhoneNumberState = (): {
  phoneState: PhoneState;
  setPhoneState(value: PhoneState): void;
} => {
  const [phoneState, setPhoneState] = useState<PhoneState>({
    fullNumber: '',
    code: '',
    phoneNumber: '',
    error: '',
    isValid: false,
  });

  return { phoneState, setPhoneState };
};

export default usePhoneNumberState;

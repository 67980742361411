import type { TPageTranslations } from '@crehana/i18n';

import { FILTER_HEADER_TRANSLATION_KEY } from '../constants';
import type { FilterHeaderLocaleKeys } from '../types';

const localePt: TPageTranslations<
  FilterHeaderLocaleKeys,
  typeof FILTER_HEADER_TRANSLATION_KEY
> = {
  [FILTER_HEADER_TRANSLATION_KEY]: {
    UD_FILTER_LABEL: 'Filtrar por',
  },
};

export default localePt;

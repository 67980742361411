import { useTranslation } from '@crehana/i18n';

import { RENEWAL_CARD_TRANSLATION_KEY } from '../constants';
import { RenewalCardLocalesBundle } from '../i18n';
import type { RenewalCardLocaleKeys } from '../types';

const useRenewalCardTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<RenewalCardLocaleKeys>(RENEWAL_CARD_TRANSLATION_KEY);

  addResourceBundle(RenewalCardLocalesBundle);

  return translationContextValue;
};

export default useRenewalCardTranslation;

import { FC, Fragment } from 'react';

import { clsx } from 'clsx';
import { css } from 'styled-components';

import { useTranslation } from '@crehana/i18n';
import CheckO from '@crehana/icons/CheckO';
import PencilOutline from '@crehana/icons/PencilOutline';
import PlaySquareOutline from '@crehana/icons/PlaySquareOutline';
import { Clamp, LazyImage, PrimaryButton, TextLink } from '@crehana/ui';
import { formatNumbers } from '@crehana/utils';
import { useUITheme } from '@crehana/web';
import { Degree } from '@crehana/web/Graphql/types';

// locales
const LOCALES = {
  es: {
    DEGREE_COMPLETED: 'MicroDegree completado',
    DOWNLOAD_DIPLOMA: 'Descargar diploma',
    DOWNLOAD_PARTICIPATION_CERTIFICATE: 'Descargar constancia',
    COURSE_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} cursos completados`,
    PROJECT_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} proyectos aprobados`,
    GO_TO_DETAIL: 'Ver detalle',
  },
  pt: {
    DEGREE_COMPLETED: 'MicroDegree completado',
    DOWNLOAD_DIPLOMA: 'Descargar diploma',
    DOWNLOAD_PARTICIPATION_CERTIFICATE: 'Descargar constancia',
    COURSE_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} cursos completados`,
    PROJECT_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} proyectos aprobados`,
    GO_TO_DETAIL: 'Ver detalle',
  },
  en: {
    DEGREE_COMPLETED: 'MicroDegree completed',
    DOWNLOAD_DIPLOMA: 'Download diploma',
    DOWNLOAD_PARTICIPATION_CERTIFICATE: 'Download participation certificate',
    COURSE_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} courses completed`,
    PROJECT_LIST_PROGRESS: ({
      completed,
      total,
    }: {
      completed: string;
      total: string;
    }) => `${completed}/${total} projects approved`,
    GO_TO_DETAIL: 'Go to detail',
  },
};

interface MyDegreeCardProps extends Degree {
  className?: string;
  goToDetail?: () => void;
  detailUrl?: string;
  buttonTestId?: string;
}

export const MyDegreeCard: FC<React.PropsWithChildren<MyDegreeCardProps>> = ({
  className,
  title,
  checkoutCoverImageUrl,
  courses,
  projects,
  certificateUrl,
  participationCertificateUrl,
  goToDetail,
  detailUrl,
  buttonTestId,
}) => {
  const { languageKey } = useTranslation();
  const STRINGS = LOCALES[languageKey as keyof typeof LOCALES];
  const { isDark } = useUITheme();
  const diplomaUrl = certificateUrl || participationCertificateUrl;

  return (
    <div
      className={clsx(
        'w-full h-full flex flex-col sm:flex-row',
        isDark ? 'bg-base-lighter-8' : 'bg-white shadow-tooltip',
        className,
      )}
      css={css`
        ${({ theme }) => theme.breakpoints.up('sm')} {
          height: 164px;
        }
      `}
    >
      <div
        className="w-full relative flex-shrink-0"
        css={css`
          padding-top: 69%;
          ${({ theme }) => theme.breakpoints.up('sm')} {
            padding-top: 0;
            width: 160px;
          }
          ${({ theme }) => theme.breakpoints.up('md')} {
            width: 243px;
          }
        `}
      >
        <LazyImage
          className="absolute inset-0 w-full h-full"
          imgClassName="object-cover"
          src={checkoutCoverImageUrl!}
          imgxParams={{ h: 250, dpr: 2, crop: 'faces' }}
          alt={title!}
          dark={isDark}
        />
      </div>
      <div className="w-full flex flex-col justify-center items-start py-16 pl-16 sm:py-0 md:pl-24">
        <Clamp className="font-subtitle2-md mb-8" clamp={2}>
          {title}
        </Clamp>
        {diplomaUrl ? (
          <Fragment>
            <p className="font-caption text-green-main flex items-center mb-16">
              <CheckO size={16} ignoreColor className="fill-current mr-8" />
              {STRINGS.DEGREE_COMPLETED}
            </p>
            <PrimaryButton
              label={
                certificateUrl
                  ? STRINGS.DOWNLOAD_DIPLOMA
                  : STRINGS.DOWNLOAD_PARTICIPATION_CERTIFICATE
              }
              href={diplomaUrl}
              size="xs"
              darkMode={isDark}
            />
          </Fragment>
        ) : (
          <Fragment>
            <p className="font-caption text-gray-dark flex items-center mb-8">
              <PlaySquareOutline
                size={16}
                ignoreColor
                className="fill-current mr-8"
              />
              {STRINGS.COURSE_LIST_PROGRESS({
                completed: formatNumbers(courses.completed).toString(),
                total: formatNumbers(courses.total).toString(),
              })}
            </p>
            <p className="font-caption text-gray-dark flex items-center">
              <PencilOutline
                size={16}
                ignoreColor
                className="fill-current mr-8"
              />
              {STRINGS.PROJECT_LIST_PROGRESS({
                completed: formatNumbers(projects.approved).toString(),
                total: formatNumbers(projects.total).toString(),
              })}
            </p>
          </Fragment>
        )}
        {(goToDetail || detailUrl) && (
          <TextLink
            className="mt-16"
            label={STRINGS.GO_TO_DETAIL}
            onClick={goToDetail}
            href={detailUrl}
            data-testid={buttonTestId}
          />
        )}
      </div>
    </div>
  );
};

export default MyDegreeCard;

import * as React from 'react';

import { Col, Container, Row } from '@crehana/ui';
import SimpleCourseCardSkeleton from '@crehana/web/components/Cards/SimpleCourseCard/SimpleCourseCardSkeleton';

interface SavedCoursesSkeletonProps {
  isDark?: boolean;
  skeletonItems?: number;
}

const SavedCoursesSkeleton: React.FC<
  React.PropsWithChildren<SavedCoursesSkeletonProps>
> = ({ skeletonItems = 8, isDark }) => (
  <Container type="dashboard">
    <Row>
      {Array.from(Array(skeletonItems)).map((_, i) => (
        <Col
          key={`course-skeleton-${i.toString()}`}
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <SimpleCourseCardSkeleton isDark={isDark} />
        </Col>
      ))}
    </Row>
  </Container>
);

export default SavedCoursesSkeleton;

import { css } from 'styled-components';

import { getHexTransparencyColor } from '@crehana/ui';

export const RenewalCardGradientBackground = css`
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    ${props => getHexTransparencyColor(props.theme.colors.purple.dark, 50)}
      1.63%,
    ${props => getHexTransparencyColor(props.theme.colors.purple.main, 85)}
      48.25%,
    ${props => props.theme.colors.purple.light} 75.41%,
    #773da6 100%
  );
`;

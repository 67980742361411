import { useState } from 'react';
import * as React from 'react';

import { Dialog } from '@crehana/ui';
import { ContactSourceEnum } from '@crehana/web/__generated__/graphql-schema';

import DialogGetPhoneContent from './components/DialogGetPhoneContent';
import useMembershipPrices from './hooks/useMembershipPrices';
import { DialogState } from './types';

interface DialogGetPhone {
  hidenDialog: () => void;
  sourceRender: ContactSourceEnum | string;
  titleCustom?: string;
  subTitleCustom?: string;
}

const DialogGetPhone: React.FC<React.PropsWithChildren<DialogGetPhone>> = ({
  hidenDialog,
  sourceRender,
  subTitleCustom,
  titleCustom,
}) => {
  const [typeDialog, setTypeDialog] = useState<DialogState>({
    isSubmit: false,
    sizeDialog: 'm',
  });
  const prices = useMembershipPrices();

  if (!prices) {
    return null;
  }

  return (
    <Dialog
      dark
      open
      onDismiss={hidenDialog}
      size={typeDialog.sizeDialog}
      className="h-screen rounded-5"
      css={`
        // This is a test of UI
        background: radial-gradient(
            65.38% 65.38% at 12.19% 5.1%,
            rgba(255, 178, 0, 0.3) 0%,
            rgba(7, 14, 39, 0) 90.33%
          ),
          radial-gradient(
            71.66% 72.21% at 89.06% 11.06%,
            rgba(242, 22, 115, 0.25) 0%,
            rgba(7, 14, 39, 0) 100%
          ),
          radial-gradient(
            143.98% 62.02% at 50% 0%,
            #3417aa 0%,
            rgba(7, 14, 39, 0) 100%
          ),
          radial-gradient(
            119.86% 119.86% at 50% -19.86%,
            rgba(7, 14, 39, 0) 23.77%,
            #070e27 100%
          ),
          #070e27;
      `}
    >
      <DialogGetPhoneContent
        typeDialog={typeDialog}
        setTypeDialog={setTypeDialog}
        hideDialog={hidenDialog}
        sourceRender={sourceRender}
        titleCustom={titleCustom}
        subTitleCustom={subTitleCustom}
        prices={prices}
      />
    </Dialog>
  );
};

export default DialogGetPhone;

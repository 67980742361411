import { FC } from 'react';

import { clsx } from 'clsx';

import { Col, Row } from '@crehana/ui';
import { useUITheme } from '@crehana/web';
import { DiplomaCardSkeleton } from '@crehana/web/components/Cards/DiplomaCard';

const DiplomaListSkeleton: FC<
  React.PropsWithChildren<{ 'data-testid'?: string }>
> = ({ 'data-testid': dataTestId }) => {
  const { isDark } = useUITheme();

  return (
    <Row gutter={16} data-testid={dataTestId}>
      {Array.from({ length: 8 }).map((_, i) => (
        <Col
          key={`diploma-skeleton-${i * 1}`}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={clsx({
            'hidden md:block': i >= 4 && i < 6,
            'hidden lg:block': i >= 6,
          })}
        >
          <DiplomaCardSkeleton isDark={isDark} />
        </Col>
      ))}
    </Row>
  );
};

export default DiplomaListSkeleton;

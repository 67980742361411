import { PROJECTS_TRANSLATION_KEY } from '../constants';

const localeEs = {
  [PROJECTS_TRANSLATION_KEY]: {
    // Filters
    UDP_FILTER_ALL_TITLE: 'Tienes {{count}} proyecto en total',
    UDP_FILTER_ALL_TITLE_plural: 'Tienes {{count}} proyectos en total',
    UDP_FILTER_CERTIFIED_TITLE: 'Tienes {{count}} proyecto certificado',
    UDP_FILTER_CERTIFIED_TITLE_plural:
      'Tienes {{count}} proyectos certificados',
    UDP_FILTER_IN_PROGRESS_TITLE: 'Tienes {{count}} proyecto en progreso',
    UDP_FILTER_IN_PROGRESS_TITLE_plural:
      'Tienes {{count}} proyectos en progreso',
    UDP_FILTER_ALL_LABEL: 'Todos',
    UDP_FILTER_CERTIFIED_LABEL: 'Certificados',
    UDP_FILTER_IN_PROGRESS_LABEL: 'En progreso',
    // Empty view
    UDP_ALL_EMPTY_TITLE: 'Aún no has iniciado un proyecto',
    UDP_ALL_EMPTY_DESC: 'Progresa en tus cursos y aplica lo que aprendes.',
    UDP_ALL_EMPTY_BUTTON: 'Ir a mis cursos',
    UDP_ALL_EMPTY_BUTTON_USER_HE: 'Ir a mis rutas',
    UDP_CERTIFIED_EMPTY_TITLE: 'Aún no tienes proyectos certificados',
    UDP_CERTIFIED_EMPTY_DESC:
      'Completa todos los entregables de tus proyectos y solicita feedback de nuestros mentores para poder obtener tu diploma.',
    UDP_CERTIFIED_EMPTY_BUTTON: 'Ver proyectos en progreso',
    UDP_IN_PROGRESS_EMPTY_TITLE: 'Actualmente no tienes proyectos en progreso',
    UDP_IN_PROGRESS_EMPTY_DESC:
      'Recuerda que para poder obtener un diploma en Crehana, no solo debes terminar tus clases, sino también aprobar tu proyecto final. Para empezar, debes ir a la sección "Tu proyecto final" dentro de tu curso, donde podrás encontrar todas las especificaciones del proyecto por entregar.',
    UDP_IN_PROGRESS_EMPTY_BUTTON: 'Ir a mis cursos',
    UDP_DEGREE_EMPTY_DESC:
      'Recuerda que a medida que avances con tus cursos, deberás realizar obligatoriamente los proyectos parciales y el proyecto final de tu MicroDegree.',
    UDP_DEGREE_EMPTY_BUTTON: 'Ir a mi MicroDegree',
    // Error view
    UDP_PROJECTS_ERROR: 'Ocurrió un error al obtener tus proyectos',
    // Project card
    UDP_EMPTY_TITLE: 'Proyecto sin título',
    UDP_FREE_COURSE: 'Curso gratis',
    UDP_UPDATED_AT: 'Última actualización {{date}}',
    UDP_PUBLISHED_AT: 'Publicado el {{date}}',
    UDP_EDIT_PROJECT: 'Edita tu proyecto',
    UDP_GO_TO_PROJECT: 'Ir al proyecto',
    // Filter header
    UD_FILTER_LABEL: 'Filtrar por',
  },
};

export default localeEs;

import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';
import { Col, InverseButton, PrimaryButton, Row } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import useUserStudyPlanTranslations from '../hooks/useUserStudyPlanTranslations';
import { TSource } from '../types';

interface ChangesSavedViewProps {
  backToEditView: () => void;
  onFinish: () => void;
  source: TSource;
}

const ChangesSavedView: FC<React.PropsWithChildren<ChangesSavedViewProps>> = ({
  backToEditView,
  onFinish,
  source,
}) => {
  const { t } = useUserStudyPlanTranslations();

  const { isDark } = useUITheme();

  return (
    <Row
      className="justify-center text-center"
      nowrap
      data-testid="study-plan-changes-saved-view"
    >
      <Col xs={12} sm={10} md={8} className="flex flex-col items-center">
        <CustomNextImage
          alt="emoji"
          src="https://crehana-public-catalog.imgix.net/images/app/gallery/2022/02/09/1f474a75/04a90fb8.png"
          className="w-32 max-w-32 h-32"
          width={32}
          height={32}
        />
        <h1 className="my-16 font-h3">{t('USP_CHANGES_SAVED_TITLE')}</h1>
        <p
          className={`font-body3 mb-24 sm:mb-48 ${
            isDark ? 'text-base-lighter' : 'text-gray-dark'
          }`}
        >
          {t('USP_CHANGES_SAVED_DESCRIPTION')}
        </p>
        <div className="flex flex-col-reverse w-full sm:flex-row sm:items-center sm:justify-center">
          <InverseButton
            label={t('USP_BACK_TO_EDIT_VIEW')}
            onClick={backToEditView}
            size="l"
            gray
            darkMode={isDark}
            data-testid="go-back-to-edit-view"
          />
          <PrimaryButton
            className="w-full mb-24 sm:mb-0 sm:ml-24 sm:w-auto"
            label={
              source === 'user-dashboard'
                ? t('USP_BACK_TO_PANEL')
                : t('USP_BACK_TO_HOME')
            }
            size="l"
            onClick={onFinish}
            darkMode={isDark}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ChangesSavedView;

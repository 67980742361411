import dynamic from 'next/dynamic';

import type { CountryPrefix } from '@crehana/ts-types';
import type { BannerProps } from '@crehana/web/Banner/types';
import type { MainMenuProps } from '@crehana/web/MainMenu';

interface MainMenuWrapperProps extends BannerProps {
  hideTopBanner?: boolean;
  countryPrefix: CountryPrefix;
  position: MainMenuProps['position'];
}

const LazyTopBanner = dynamic(() => import('@crehana/web/Banner/Banner'), {
  ssr: false,
});

const MainMenuWrapper: React.FC<
  React.PropsWithChildren<MainMenuWrapperProps>
> = ({
  amplitudePageName,
  hideTopBanner,
  countryPrefix,
  position,
  onToggleTopBanner,
  customBannerDataParser,
  graphqlVariables,
  children,
}) => {
  return (
    <>
      {hideTopBanner ? null : (
        <LazyTopBanner
          amplitudePageName={amplitudePageName}
          onToggleTopBanner={onToggleTopBanner}
          countryPrefix={countryPrefix}
          customBannerDataParser={customBannerDataParser}
          graphqlVariables={graphqlVariables}
        />
      )}
      {!hideTopBanner && position === 'absolute' ? (
        <header className="relative">{children}</header>
      ) : (
        children
      )}
    </>
  );
};

export default MainMenuWrapper;

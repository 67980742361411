import { TPageTranslations } from '@crehana/i18n';

import { USER_STUDY_PLAN_TRANSLATION_KEY } from '../constants';
import { UserStudyPlanTranslationKeys } from '../i18n';

type TTranslations = TPageTranslations<
  UserStudyPlanTranslationKeys,
  typeof USER_STUDY_PLAN_TRANSLATION_KEY
>;

const pt: TTranslations = {
  [USER_STUDY_PLAN_TRANSLATION_KEY]: {
    USP_STUDY_PLAN_TITLE: 'Programe seu horário de estudo',
    USP_STUDY_PLAN_DESCRIPTION:
      'Definir uma meta de aprendizado ajudará a manter sua motivação. Diga-nos quanto tempo você passará estudando na Crehana e nós ajudaremos você a atingir seus objetivos.',
    USP_CREATE_STUDY_PLAN: 'Montar plano de estudos',
    USP_CREATING_STUDY_PLAN: 'Montando plano de estudos',
    USP_SAVE_CHANGES: 'Guardar alterações',
    USP_SAVING_CHANGES: 'Salvando alterações',
    USP_ENABLE_NOTIFICATIONS:
      'Quero receber notificações e acompanhamento do meu plano de estudos',
    USP_CHANGES_SAVED_TITLE: 'Seu plano de estudo foi salvo com sucesso!',
    USP_CHANGES_SAVED_DESCRIPTION:
      'Você pode ver seu progresso na guia da sua atividade. Caso seus objetivos de estudo mudem, lembre-se de que você pode alterar seu plano de estudos sempre que quiser.',
    USP_BACK_TO_HOME: 'Voltar à página inicial',
    USP_BACK_TO_PANEL: 'Voltar ao meu painel',
    USP_BACK_TO_EDIT_VIEW: 'Continuar editando',
    // TARGET
    USP_TARGET_TITLE: 'Qual seria seu objetivo semanal de estudo na Crehana?',
    USP_TARGET_CHILL_LABEL: 'De boa',
    USP_TARGET_REGULAR_LABEL: 'Regular',
    USP_TARGET_INTENSIVE_LABEL: 'Intensivo',
    USP_TARGET_WEEKLY_TIME: '{{time}} min por semana',
    // FREQUENCY
    USP_FREQUENCY_TITLE: 'Quantas vezes você gostaria de aprender?',
    USP_FREQUENCY_ONE_LABEL: '1 vez',
    USP_FREQUENCY_TWO_LABEL: '2 vezes',
    USP_FREQUENCY_THREE_LABEL: '3 vezes',
    USP_FREQUENCY_FIVE_LABEL: '5 vezes',
    USP_FREQUENCY_ALL_LABEL: 'Todos os dias da semana',
    USP_FREQUENCY_WEEKLY: 'por semana',
    // Error messages
    USP_QUERY_ERROR: 'Ocorreu um erro ao obter seu plano de estudo',
    USP_TRY_AGAIN: 'Tente novamente',
    USP_CREATE_ERROR:
      'Ocorreu um erro ao criar seu plano de estudo. Por favor, tente novamente.',
    USP_UPDATE_ERROR:
      'Não foi possível salvar suas alterações. Por favor, tente novamente.',
  },
};

export default pt;

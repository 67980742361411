import { CourseTypeEnum as CourseTypeEnumLegacy, EnrollmentTypeEnum, StatusProjectEnum, UserTagEnum } from "../../__generated__/graphql-schema";
import { CourseTypeEnum } from "../../__generated__/graphql-schema.v4";
var getProjectType = function getProjectType(_ref) {
  var user = _ref.user,
    course = _ref.course,
    enrollment = _ref.enrollment,
    project = _ref.project;
  if (user.originalId && course.teacherId && user.originalId === course.teacherId) {
    return 'teacher';
  }
  if (course.courseTypeEnum === CourseTypeEnumLegacy.COURSE_TYPE_DEGREE || course.courseTypeEnum === CourseTypeEnum.DEGREE) {
    return 'degree';
  }
  if (course.courseTypeEnum === CourseTypeEnumLegacy.COURSE_TYPE_FREE || course.courseTypeEnum === CourseTypeEnum.FREE) {
    return 'free';
  }
  // Validate if the project is already in the mentorship flux
  if (project.currentActionState && project.currentActionState !== StatusProjectEnum.DRAFT) {
    return 'mentorship';
  }
  if (enrollment.enrollmentTypeEnum === EnrollmentTypeEnum.ENROLLMENT_FREE_MEMBERSHIP || enrollment.enrollmentTypeEnum === EnrollmentTypeEnum.ENROLLMENT_TEMPORAL_ACCESS || user.userTagV2 === UserTagEnum.COLLEGE_SCHOLARSHIP || !enrollment.projectFeedbackIsAllowed) {
    return 'participation';
  }
  return 'mentorship';
};
export default getProjectType;
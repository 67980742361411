import fecha from 'fecha';
import { CUSTOM_FORMAT } from "./constants";
import locales from "./locales";
var toString = function toString(date) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CUSTOM_FORMAT;
  var lang = arguments.length > 2 ? arguments[2] : undefined;
  fecha.i18n = locales[lang];
  var d = fecha.format(date, fmt);
  return d.charAt(0).toUpperCase().concat(d.slice(1));
};
export default toString;
import { useTranslation } from '@crehana/i18n';

import { USER_STUDY_PLAN_TRANSLATION_KEY } from '../constants';
import locales, { type UserStudyPlanTranslationKeys } from '../i18n';

const useUserStudyPlanTranslations = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<UserStudyPlanTranslationKeys>(
      USER_STUDY_PLAN_TRANSLATION_KEY,
    );

  addResourceBundle(locales);

  return translationContextValue;
};

export default useUserStudyPlanTranslations;

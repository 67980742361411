import { FC } from 'react';
import * as React from 'react';

import { Dialog } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import type { TSource } from './types';
import UserStudyPlan from './UserStudyPlan';

interface UserStudyPlanDialogProps {
  open?: boolean;
  onDismiss: () => void;
  onFinish?: () => void;
  source?: TSource;
  'data-testid'?: string;
}

const UserStudyPlanDialog: FC<
  React.PropsWithChildren<UserStudyPlanDialogProps>
> = ({ open, onDismiss, onFinish, source, 'data-testid': dataTestId }) => {
  const { isDark } = useUITheme();

  return (
    <Dialog
      open={open}
      onDismiss={onDismiss}
      size="full"
      isDark={isDark}
      className="pt-64 pb-48 sm:pb-56"
    >
      <UserStudyPlan
        onFinish={onFinish || onDismiss}
        source={source}
        data-testid={dataTestId}
      />
    </Dialog>
  );
};

export default UserStudyPlanDialog;

import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { transformData } from '@crehana/utils';
import { useMe } from '@crehana/web';
import MY_PROJECTS_QUERY from '@crehana/web/Graphql/Queries/MyProjectsQuery.graphql';
import {
  MyProjectsQuery as MyProjectsQueryResponse,
  MyProjectsQueryVariables,
} from '@crehana/web/Graphql/Queries/types/MyProjectsQuery';
import {
  DegreeSyllabusStatusEnum,
  MyProject,
} from '@crehana/web/Graphql/types';

const useMyProjects = (variables?: Partial<MyProjectsQueryVariables>) => {
  const { me, loading: loadingMe, error: meError } = useMe();

  const [
    myProjectsLazyQuery,
    { data, loading, called, refetch, error, ...myProjectsQuery },
  ] = useLazyQuery<MyProjectsQueryResponse, MyProjectsQueryVariables>(
    MY_PROJECTS_QUERY,
    { notifyOnNetworkStatusChange: true },
  );

  useEffect(() => {
    if (me?.originalId) {
      const queryVariables = { userId: me.originalId.toString(), ...variables };

      if (called && refetch) {
        refetch(queryVariables);
      } else {
        myProjectsLazyQuery({ variables: queryVariables });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    me?.originalId,
    variables?.first,
    variables?.after,
    variables?.currentActionState,
  ]);

  const projects: MyProject[] = transformData(data?.projects).map(project => ({
    ...project,
    absoluteUrl:
      project.absoluteUrl ||
      `/clases/v2/${project.course?.originalId}/proyecto/crear/`,
    isFromDegree: !!project.degreeLearningDetail?.degreeLearning,
    isExpired:
      project.degreeLearningDetail?.degreeLearning?.degreeSyllabus
        .statusEnum === DegreeSyllabusStatusEnum.STATUS_FINISHED,
  }));

  return {
    loading: !called || loadingMe || loading,
    error: error || meError,
    projects,
    refetch,
    ...myProjectsQuery,
  };
};

export default useMyProjects;

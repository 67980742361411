import { FC } from 'react';

import { clsx } from 'clsx';
import { css as cssSC } from 'styled-components';

import { useUITheme } from '@crehana/web';

interface OptionProps {
  title?: string;
  subtitle: string;
  selected?: boolean;
  onClick: () => void;
  className?: string;
  css?: string;
  'data-testid'?: string;
}

const Option: FC<React.PropsWithChildren<OptionProps>> = ({
  title,
  subtitle,
  selected,
  onClick,
  className,
  css,
  'data-testid': dataTestId,
}) => {
  const { isDark } = useUITheme();

  const buttonCls = isDark
    ? 'bg-base-lighter-24 text-base-lighter'
    : 'border border-solid border-gray-main text-base-lighter';
  const selectedButtonCls = isDark
    ? 'bg-primary-lighter text-white'
    : 'border border-solid border-transparent bg-purple-lighter-2 text-primary-main';

  return (
    <button
      type="button"
      className={clsx(
        'w-full flex items-center',
        selected ? selectedButtonCls : buttonCls,
        className,
      )}
      css={cssSC`
        transition: ${({ theme }) =>
          theme.transition(['background-color', 'border', 'color'])};
        ${css}
      `}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {title && <span className="font-h4 sm:mb-4">{title}</span>}
      <span className="font-body3">{subtitle}</span>
    </button>
  );
};

export default Option;

import { RENEWAL_CARD_TRANSLATION_KEY } from '../constants';

const localeEs = {
  [RENEWAL_CARD_TRANSLATION_KEY]: {
    CARD_RENEWAL_TITLE_WITH_NAME:
      '{{firstname}}, ¡felicitaciones por tus logros de este año!',
    CARD_RENEWAL_TITLE_WITHOUT_NAME:
      '¡Felicitaciones por tus logros de este año!',
    CARD_RENEWAL_MESSAGE:
      'Te quedan {{remainingDays}} días más para disfrutar de tu membresía {{membershipType}}. ¿Vamos por 1 año más? Renueva y sigue creciendo.',
    CARD_RENEWAL_CTA: 'Quiero renovar',
  },
};

export default localeEs;

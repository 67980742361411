import { TPageTranslations } from '@crehana/i18n';

import { USER_STUDY_PLAN_TRANSLATION_KEY } from '../constants';
import { UserStudyPlanTranslationKeys } from '../i18n';

type TTranslations = TPageTranslations<
  UserStudyPlanTranslationKeys,
  typeof USER_STUDY_PLAN_TRANSLATION_KEY
>;

const en: TTranslations = {
  [USER_STUDY_PLAN_TRANSLATION_KEY]: {
    USP_STUDY_PLAN_TITLE: 'Schedule your study time',
    USP_STUDY_PLAN_DESCRIPTION:
      'Setting a learning goal will help you stay motivated. Tell us how much time you will spend studying at Crehana and we will help you achieve your goals.',
    USP_CREATE_STUDY_PLAN: 'Create study plan',
    USP_CREATING_STUDY_PLAN: 'Creating study plan',
    USP_SAVE_CHANGES: 'Save changes',
    USP_SAVING_CHANGES: 'Saving changes',
    USP_ENABLE_NOTIFICATIONS:
      'I want to receive notifications and updates about my study plan',
    USP_CHANGES_SAVED_TITLE: 'Your study plan has been successfully saved!',
    USP_CHANGES_SAVED_DESCRIPTION:
      'You can track your progress in the activity tab. If your study goals change, remember that you can modify your study plan whenever you want.',
    USP_BACK_TO_HOME: 'Back to home page',
    USP_BACK_TO_PANEL: 'Back to my panel',
    USP_BACK_TO_EDIT_VIEW: 'Continue editing',
    // TARGET
    USP_TARGET_TITLE: 'What would be your weekly study goal at Crehana?',
    USP_TARGET_CHILL_LABEL: 'Chill',
    USP_TARGET_REGULAR_LABEL: 'Regular',
    USP_TARGET_INTENSIVE_LABEL: 'Intensive',
    USP_TARGET_WEEKLY_TIME: '{{time}} min per week',
    // FREQUENCY
    USP_FREQUENCY_TITLE: 'How often would you like to learn?',
    USP_FREQUENCY_ONE_LABEL: '1 time',
    USP_FREQUENCY_TWO_LABEL: '2 times',
    USP_FREQUENCY_THREE_LABEL: '3 times',
    USP_FREQUENCY_FIVE_LABEL: '5 times',
    USP_FREQUENCY_ALL_LABEL: 'Every day of the week',
    USP_FREQUENCY_WEEKLY: 'per week',
    // Error messages
    USP_QUERY_ERROR: 'An error occurred while retrieving your study plan',
    USP_TRY_AGAIN: 'Try again',
    USP_CREATE_ERROR:
      'An error occurred while creating your study plan. Please try again.',
    USP_UPDATE_ERROR: 'Unable to save your changes. Please try again.',
  },
};

export default en;

import { FC } from 'react';

import { clsx } from 'clsx';

import { Col, PrimaryButton, Row } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import { useDashboardErrorViewTranslation } from './hooks';

export interface DashboardErrorViewProps {
  className?: string;
  error?: string;
  onRetry?: () => void;
  /* Provide this prop to show default internet connection error message and button. */
  defaultConnectionError?: boolean;
  supportsDarkMode?: boolean;
  'data-testid'?: string;
}

const DashboardErrorView: FC<
  React.PropsWithChildren<DashboardErrorViewProps>
> = ({
  className = '',
  error,
  onRetry,
  defaultConnectionError,
  supportsDarkMode,
  'data-testid': dataTestId,
}) => {
  const { isDark } = useUITheme();
  const { t } = useDashboardErrorViewTranslation();
  const dark = supportsDarkMode && isDark;

  const onReload = () => {
    window.location.reload();
  };

  return (
    <div
      className={clsx(
        'w-full',
        dark ? 'bg-base-lighter-24' : 'bg-base-lighter-16',
        className,
      )}
      data-testid={dataTestId}
    >
      <Row nowrap className="flex justify-center">
        <Col xs={12} sm={10} lg={8}>
          <div className="flex flex-col items-center text-center py-16 px-16 sm:py-32 sm:px-0">
            <h4
              className={`font-subtitle2 ${
                dark ? 'text-white' : 'text-base-main'
              }`}
            >
              {error ||
                (defaultConnectionError
                  ? t('INTERNET_CONNECTION_ERROR')
                  : t('GENERAL_ERROR'))}
            </h4>
            {(onRetry || defaultConnectionError) && (
              <PrimaryButton
                className="w-full sm:w-auto mt-16"
                label={
                  defaultConnectionError ? t('RELOAD_PAGE') : t('TRY_AGAIN')
                }
                onClick={onRetry || onReload}
                darkMode={dark}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardErrorView;

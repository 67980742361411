import toDate from "./toDate";

/**
 * Compares 2 dates and returns the greater one.
 * You can provide the date object itself or a date string (FULL_FORMAT)
 */
var getGreaterDate = function getGreaterDate(date1Param, date2Param) {
  var date1 = typeof date1Param === 'string' ? toDate(date1Param) : date1Param;
  var date2 = typeof date2Param === 'string' ? toDate(date2Param) : date2Param;
  if (!date1 && !date2) {
    return undefined;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  return date1.getTime() > date2.getTime() ? date1 : date2;
};
export default getGreaterDate;
import dynamic from 'next/dynamic';

import { Container } from '@crehana/ui';

import CourseListSkeleton from './components/CourseListSkeleton';

const LazyCourses = dynamic(
  () => import(/* webpackChunkName: "user-dashboard-courses" */ './Courses'),
  {
    ssr: false,
    loading: () => (
      <Container type="dashboard">
        <CourseListSkeleton />
      </Container>
    ),
  },
);

export default LazyCourses;

import { FC } from 'react';

import { clsx } from 'clsx';

import { Col, Row, Skeleton } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

const ProjectListSkeleton: FC<
  React.PropsWithChildren<{ 'data-testid'?: string }>
> = ({ 'data-testid': dataTestId }) => {
  const { isDark } = useUITheme();

  return (
    <Row gutter={16} data-testid={dataTestId}>
      {Array.from({ length: 8 }).map((_, i) => (
        <Col
          key={`project-skeleton-${i * 1}`}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={clsx({
            'hidden md:block': i >= 4 && i < 6,
            'hidden lg:block': i >= 6,
          })}
        >
          <div
            className={`w-full h-full flex flex-col ${
              isDark ? 'bg-base-lighter-16' : 'bg-white shadow-tooltip'
            }`}
          >
            <Skeleton
              className="w-full h-280 sm:h-212 flex-shrink-0 z-1"
              isDark={isDark}
            />
            <div className="w-full h-full flex flex-col justify-between p-16">
              <div className="flex flex-col items-start mb-24">
                <Skeleton className="h-32 w-104 mb-16" isDark={isDark} />
                <Skeleton className="h-20 w-full mb-8" isDark={isDark} />
                <Skeleton className="h-16 w-full mb-8" isDark={isDark} />
                <Skeleton className="h-16 w-2/3" isDark={isDark} />
              </div>
              <Skeleton className="h-20 w-144" isDark={isDark} />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ProjectListSkeleton;

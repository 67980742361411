import { useQuery } from '@apollo/client';

import { transformData } from '@crehana/utils';

import { MyDiplomasCustom } from '@/views/LearnDashboard/layouts/shared/DiplomasSection/type';

import PARTICIPATION_CERTIFICATES_QUERY from '../graphql/ParticipationCertificatesQuery.graphql';
import { participationCertificatesQuery as ParticipationCertificatesResponse } from '../graphql/types/participationCertificatesQuery';

export const useParticipationCertificates = () => {
  const { data, ...participationCertificates } =
    useQuery<ParticipationCertificatesResponse>(
      PARTICIPATION_CERTIFICATES_QUERY,
      {
        notifyOnNetworkStatusChange: true,
      },
    );

  const certificatesData: MyDiplomasCustom[] = transformData(
    data?.participationCertificates,
  ).map(
    certificate =>
      ({
        ...certificate,
        createdDate: certificate.createdAt,
        previewImage: certificate.imageThumbnailUrl,
        diplomaUrl: certificate.certificateUrl,
      } as unknown as MyDiplomasCustom),
  );

  return {
    certificatesData,
    ...participationCertificates,
  };
};

export var CLASSNAMES_BY_TEXT_TYPE = function CLASSNAMES_BY_TEXT_TYPE() {
  var responsiveBreakpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'sm';
  var isMd = responsiveBreakpoint === 'md';
  return {
    'main-title': isMd ? 'font-main-title-md' : 'font-main-title',
    'main-title-reg': isMd ? 'font-main-title-reg-md' : 'font-main-title-reg',
    h1: isMd ? 'font-h1-md' : 'font-h1',
    'h1-reg': isMd ? 'font-h1-reg-md' : 'font-h1-reg',
    h2: isMd ? 'font-h2-md' : 'font-h2',
    'h2-reg': isMd ? 'font-h2-reg-md' : 'font-h2-reg',
    h3: isMd ? 'font-h3-md' : 'font-h3',
    'h3-reg': isMd ? 'font-h3-reg-md' : 'font-h3-reg',
    h4: isMd ? 'font-h4-md' : 'font-h4',
    'h4-reg': isMd ? 'font-h4-reg-md' : 'font-h4-reg',
    subtitle1: isMd ? 'font-subtitle1-md' : 'font-subtitle1',
    subtitle2: isMd ? 'font-subtitle2-md' : 'font-subtitle2',
    subtitle3: 'font-subtitle3',
    subtitle4: 'font-subtitle4',
    body1: isMd ? 'font-body1-md' : 'font-body1',
    body2: isMd ? 'font-body2-md' : 'font-body2',
    body3: 'font-body3',
    body4: 'font-body4',
    legals: 'font-legals',
    label: 'font-label',
    caption: 'font-caption',
    button: isMd ? 'font-button-md' : 'font-button',
    link: 'font-link',
    'link-2': 'font-link-2',
    currency: isMd ? 'font-currency-md' : 'font-currency',
    price: isMd ? 'font-price-md' : 'font-price',
    discount: 'font-discount',
    'currency-s': isMd ? 'font-currency-s-md' : 'font-currency-s',
    'price-s': isMd ? 'font-price-s-md' : 'font-price-s',
    'discount-s': isMd ? 'font-discount-s-md' : 'font-discount-s',
    'currency-xs': 'font-currency-xs',
    'price-xs': 'font-price-xs',
    'discount-xs': 'font-discount-xs'
  };
};
export var LINE_HEIGHT_VALUES_BY_TEXT_TYPE = {
  'main-title': {
    mobile: 44,
    desktop: 64
  },
  'main-title-reg': {
    mobile: 44,
    desktop: 64
  },
  h1: {
    mobile: 36,
    desktop: 60
  },
  'h1-reg': {
    mobile: 36,
    desktop: 60
  },
  h2: {
    mobile: 28,
    desktop: 44
  },
  'h2-reg': {
    mobile: 28,
    desktop: 44
  },
  h3: {
    mobile: 24,
    desktop: 40
  },
  'h3-reg': {
    mobile: 24,
    desktop: 40
  },
  h4: {
    mobile: 24,
    desktop: 28
  },
  'h4-reg': {
    mobile: 24,
    desktop: 28
  },
  subtitle1: {
    mobile: 24,
    desktop: 28
  },
  subtitle2: {
    mobile: 20,
    desktop: 24
  },
  subtitle3: 20,
  subtitle4: 18,
  body1: 28,
  body2: 24,
  body3: 24,
  body4: 20,
  legals: 16,
  label: 16,
  caption: 16,
  button: {
    mobile: 16,
    desktop: 20
  },
  link: 16,
  'link-2': 16,
  currency: {
    mobile: 24,
    desktop: 32
  },
  price: {
    mobile: 24,
    desktop: 32
  },
  discount: 20,
  'currency-s': {
    mobile: 14,
    desktop: 32
  },
  'price-s': {
    mobile: 24,
    desktop: 32
  },
  'discount-s': {
    mobile: 16,
    desktop: 20
  },
  'currency-xs': 20,
  'price-xs': 20,
  'discount-xs': 16
};
export default CLASSNAMES_BY_TEXT_TYPE;
import Checkmark from '@crehana/compass.icons.outline/Checkmark';
import { cn } from '@crehana/compass.ui/v2';

type ListFilterOptionsProps = {
  items: {
    value: string;
    label: string;
  }[];
  selectedValue: string;
  onChange?: (value: string) => void;
};

const ListFilterOptions: React.FC<ListFilterOptionsProps> = ({
  items,
  selectedValue,
  onChange,
}) => {
  return (
    <>
      {items.map(item => {
        const isFilterSelected = selectedValue === item.value;
        return (
          <button
            type="button"
            key={item.value}
            onClick={() => onChange?.(item.value)}
            className={cn(
              'tw-group tw-relative tw-flex tw-gap-1.2 tw-w-full tw-justify-between tw-cursor-pointer tw-select-none tw-items-center tw-rounded-sm tw-outline-none',
              'tw-font-body4 tw-py-1.2 tw-px-2.4 tw-h-4.8 dark:tw-text-neutral-light-100 tw-rounded-0.8',
              'data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50',
              isFilterSelected &&
                'tw-bg-neutral-light-300 dark:tw-bg-neutral-dark-600 tw-text-primary-light-500',
            )}
          >
            {item.label}
            {isFilterSelected && (
              <span className="tw-flex tw-items-center tw-w-2.4 tw-h-full">
                <Checkmark className="tw-text-success-400" size={24} />
              </span>
            )}
          </button>
        );
      })}
    </>
  );
};

export default ListFilterOptions;
